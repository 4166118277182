import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { MOBILE_MEDIA_QUERY, MOBILE_SMALL_MEDIA_QUERY, TABLET_PORTRAIT_MEDIA_QUERY } from '../../../constants';
import { TaskResource } from '../../pages/NewProject/NewProjectSummary/types';
import JiraSVG from '../../../public/media/jira.svg';
import styles from '../ProjectTracking.module.scss';
import { DurationCell } from './DurationCell.tsx';

export const TaskCell = ({ task, hasJiraIntegration }: { task: TaskResource, hasJiraIntegration?: boolean }) => {
  const { t } = useTranslation();

  const isTabletPortraitDevice = useMediaQuery(TABLET_PORTRAIT_MEDIA_QUERY);
  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);
  const isMobileSmallDevice = useMediaQuery(MOBILE_SMALL_MEDIA_QUERY);

  return (
    <div className={styles.taskCell}>
      {(isTabletPortraitDevice || isMobileDevice || isMobileSmallDevice || !hasJiraIntegration || !task?.jira_link) ? null : (
        <a
          className={classNames(styles.jiraLink, {
            [styles.disabled]: !task.jira_link,
          })}
          href={task.jira_link}
          target='_blank'
          rel='noreferrer'
          aria-label={t('Open task in Jira')}
        >
          <img
            src={JiraSVG}
            alt='jira'
          />
        </a>
      )}
      <div className={styles.taskCellNameDurationMobileWrapper}>
        <p className={styles.taskName}>{task.name}</p>
        <DurationCell
          task={task}
          className={classNames(styles.mobileDuration, styles.hideNotMobile)}
        />
      </div>
    </div>
  );
};
