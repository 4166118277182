import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { notify } from '../../../store/notifications.ts';
import { TaskResource } from '../../pages/NewProject/NewProjectSummary/types.ts';
import Button, { ButtonVariants } from '../../UIKit/Button/Button.tsx';
import { PopoverPlacement } from '../../UIKit/Popover/Popover.tsx';
import PopoverOptions from '../../UIKit/PopoverOptions/PopoverOptions.tsx';
import {
  MOBILE_MEDIA_QUERY,
  MOBILE_SMALL_MEDIA_QUERY,
  TABLET_PORTRAIT_MEDIA_QUERY,
} from '../../../constants.ts';
import AngleDownSVG from '../../../public/media/angle-down.svg';
import styles from '../ProjectTracking.module.scss';
import { JiraStatus } from '../interfaces.ts';
import { UpdatedTask, updateTask } from '../api.ts';
import Loader from '../../Loader/Loader.tsx';

export type GetTaskError = { message: string };
export type GetTaskTask = { data: TaskResource };

interface CustomCellTemplateData {
  task: TaskResource;
  updateTasks: (task: TaskResource, isStatusChange?: boolean) => void;
  className?: string,
  resetToJira?: (id: number) => Promise<void>,
  hasJiraIntegration?: boolean,
  canResetToJira?: boolean,
  isReadOnlyProjectAccess?: boolean,
  getTask: (id: number, projectId: string) => Promise<GetTaskError | GetTaskTask | undefined>,
  jiraIntegrationFailed?: boolean,
}

export const StatusCell = ({
  task,
  updateTasks,
  className,
  resetToJira,
  canResetToJira = false,
  getTask,
  hasJiraIntegration,
  isReadOnlyProjectAccess,
  jiraIntegrationFailed,
}: CustomCellTemplateData) => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { status } = task;

  const updateParentTasks = async (updatedTask: TaskResource) => {
    if (updatedTask.parent_id) {
      const updatedParentResponse = await getTask(updatedTask.parent_id, projectId!);
      const updatedParent = (updatedParentResponse as GetTaskTask)?.data;
      updateTasks(updatedParent);

      updatedParent?.parent_id && await updateParentTasks(updatedParent);
    }
  };

  const updateStatus = async ({ status: { value }, taskId }: { status: { value: JiraStatus }, taskId: number }) => {
    try {
      const { data: updatedTask } = await updateTask(projectId!, taskId, { status: value }) as UpdatedTask;
      updateTasks(updatedTask, true);
      await updateParentTasks(updatedTask);
    } catch (e) {
      notify({ text: { body: e?.message ?? '', title: t('An error occurred on status updating') } });
    }
  };

  const readOnly = task.has_children;

  const statusTypes = [
    {
      id: 0,
      title: (
        <div className={styles.statusItem}>
          <div
            className={classNames(styles.statusItem__indicator, styles.statusItem__indicator_grey)}
          />
          <p className={styles.statusItem__text}>{t('Not started')}</p>
        </div>
      ),
      handler: () => updateStatus({ status: { value: JiraStatus.NOT_STARTED }, taskId: task.id }),
    },
    {
      id: 1,
      title: (
        <div className={styles.statusItem}>
          <div
            className={classNames(
              styles.statusItem__indicator,
              styles.statusItem__indicator_orange,
            )}
          />
          <p className={styles.statusItem__text}>{t('In progress')}</p>
        </div>
      ),
      handler: () => updateStatus({ status: { value: JiraStatus.IN_PROGRESS }, taskId: task.id }),
    },
    {
      id: 2,
      title: (
        <div className={styles.statusItem}>
          <div
            className={classNames(styles.statusItem__indicator, styles.statusItem__indicator_green)}
          />
          <p className={styles.statusItem__text}>{t('Done')}</p>
        </div>
      ),
      handler: () => updateStatus({ status: { value: JiraStatus.DONE }, taskId: task.id }),
    },
  ];

  const isTabletPortraitDevice = useMediaQuery(TABLET_PORTRAIT_MEDIA_QUERY);
  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);
  const isMobileSmallDevice = useMediaQuery(MOBILE_SMALL_MEDIA_QUERY);

  return (
    <div className={classNames(styles.statusBlock, className)}>
      {status?.value === JiraStatus.LOADING ? (
        <Loader
          className={styles.statusButton_loading}
          size={15}
        />
      ) : readOnly || isReadOnlyProjectAccess ? (
        <div className={classNames(styles.statusButton, styles.statusButton_readOnly, {
          [styles.statusButton_green]: status?.value === JiraStatus.DONE,
          [styles.statusButton_orange]: status?.value === JiraStatus.IN_PROGRESS,
          [styles.statusButton_grey]: status?.value === JiraStatus.NOT_STARTED,
        })}
        >
          <div className={styles.statusButton__indicator} />
          <p className={styles.statusItem__text}>{status?.caption}</p>
        </div>
      ) : (
        <PopoverOptions
          placement={PopoverPlacement.BOTTOM}
          customButton={(
            <div className={classNames(styles.statusButton, {
              [styles.statusButton_green]: status?.value === JiraStatus.DONE,
              [styles.statusButton_orange]: status?.value === JiraStatus.IN_PROGRESS,
              [styles.statusButton_grey]: status?.value === JiraStatus.NOT_STARTED,
            })}
            >
              <div className={styles.statusButton__indicator} />
              {status?.caption}
              <svg className={styles.angleSVG}>
                <use
                  xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                  href={`${AngleDownSVG}#angleDownSVG`}
                />
              </svg>
            </div>
          )}
          options={statusTypes}
          paperClassName={styles.statusPaper}
        />
      )}
      {hasJiraIntegration && !!resetToJira && !jiraIntegrationFailed && (
        (isTabletPortraitDevice || isMobileDevice || isMobileSmallDevice)
          ? null
          : canResetToJira && !isReadOnlyProjectAccess && (
          <Button
            className={styles.statusJiraButton}
            variant={ButtonVariants.SECONDARY}
            onClick={() => resetToJira(task.id)}
          >
            {t('Reset to Jira status')}
          </Button>
          )
      )}
    </div>
  );
};
