import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Menu.module.scss';
import { DropdownDisplay } from '../../interfaces.ts';
import EyeSVG from '../../../../public/media/eye.svg';
import OpenInNewTabSVG from '../../../../public/media/open_in_new_tab.svg';
import BackSVG from '../../../../public/media/back.svg';
import AngleRightSVG from '../../../../public/media/angle_right.svg';
import DocumentSVG from '../../../../public/media/document.svg';

type MenuProps = {
  jiraLink: string | null,
  canResetToJira: boolean,
  showOnDropdown: (dropdownItem: DropdownDisplay) => void,
  linksCount: number,
  isReadOnlyProjectAccess?: boolean,
};
const Menu = ({
  canResetToJira,
  showOnDropdown,
  jiraLink,
  linksCount,
  isReadOnlyProjectAccess,
}: MenuProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {jiraLink && (
        <a
          className={styles.dropdownItem}
          href={jiraLink}
          target='_blank'
          rel='noreferrer'
          aria-label={t('View in Jira')}
        >
          <svg className={styles.iconSVG}>
            <use
              xlinkHref={`${EyeSVG}#eyeSVG`}
              href={`${EyeSVG}#eyeSVG`}
            />
          </svg>
          <span className={styles.itemText}>{t('View in Jira')}</span>
          <svg className={styles.nextIconSVG}>
            <use
              xlinkHref={`${OpenInNewTabSVG}#openInNewTabSVG`}
              href={`${OpenInNewTabSVG}#openInNewTabSVG`}
            />
          </svg>
        </a>
      )}
      {
        canResetToJira && !isReadOnlyProjectAccess && (
          <button
            type='button'
            className={styles.dropdownItem}
            onClick={() => showOnDropdown(DropdownDisplay.RESET_TO_JIRA_STATUS)}
          >
            <svg className={styles.iconSVG}>
              <use
                xlinkHref={`${BackSVG}#backSVG`}
                href={`${BackSVG}#backSVG`}
              />
            </svg>
            <span className={styles.itemText}>{t('Reset to Jira status')}</span>
            <svg className={styles.nextIconSVG}>
              <use
                xlinkHref={`${AngleRightSVG}#angleRightSVG`}
                href={`${AngleRightSVG}#angleRightSVG`}
              />
            </svg>
          </button>
        )
      }
      <button
        type='button'
        className={styles.dropdownItem}
        onClick={() => showOnDropdown(DropdownDisplay.LINKS)}
      >
        <svg className={styles.iconSVG}>
          <use
            xlinkHref={`${DocumentSVG}#documentSVG`}
            href={`${DocumentSVG}#documentSVG`}
          />
        </svg>
        <span className={styles.itemText}>{`${t('Links')} (${linksCount})`}</span>
        <svg className={styles.nextIconSVG}>
          <use
            xlinkHref={`${AngleRightSVG}#angleRightSVG`}
            href={`${AngleRightSVG}#angleRightSVG`}
          />
        </svg>
      </button>
    </div>
  );
};

export default Menu;
