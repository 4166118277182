import React from 'react';
import classNames from 'classnames';

import Logo from './Logo';
import LangSelector from '../LangSelector/LangSelector';

import styles from './Header.module.scss';

const PublicHeader = () => (
  <header className={classNames(styles.header, styles.header_public)}>
    <Logo visibleOnMobile />
    <LangSelector />
  </header>
);

export default PublicHeader;
