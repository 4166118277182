import { DragEndEvent, DragStartEvent } from '@dnd-kit/core/dist/types';
import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useParams } from 'react-router-dom';
import apiClient from '../../../../apiClient.ts';
import EmptyListSVG from '../../../../public/media/empty-list.svg';
import MenuSVG from '../../../../public/media/menu.svg';
import PlusSVG from '../../../../public/media/plus.svg';
import { notify } from '../../../../store/notifications.ts';
import SubHeader from '../../../SubHeader/SubHeader.tsx';
import Button, { ButtonVariants } from '../../../UIKit/Button/Button.tsx';
import Drawer from '../../../UIKit/Drawer/Drawer.tsx';
import EditDeliverableForm from '../../QualityGate/QualityGateDetails/DeliverableForm/EditDeliverableForm.tsx';
import AddDeliverableForm from './AddDeliverableForm/AddDeliverableForm.tsx';
import { activeTask } from './manageDeliverables.atom.ts';
import styles from './ManageDeliverables.module.scss';
import 'devextreme/dist/css/dx.light.css';
import { Deliverable } from './manageDeliverables.types';
import ManageDeliverablesTreelist from './ManageDeliverablesTreelist/ManageDeliverablesTreelist.tsx';
import SortableDeliverable from './SortableDeliverable/SortableDeliverable.tsx';

const getTaskDeliverables = async (projectId: string, taskId?: number, firstLoading?: boolean) => {
  try {
    return await apiClient.get<{ data: Deliverable[] }>(`projects/${projectId}/deliverables?page=all&all=true&order_by=order${taskId
      ? (`&task=${taskId}`) : ''}${firstLoading ? '&load_first_task_deliverables=true' : ''}`);
  } catch (e) {
    throw new Error(e.message);
  }
};

const ManageDeliverables = () => {
  const { t } = useTranslation();
  const shouldShowTasksDrawer = useMediaQuery('(max-width: 1023px)');

  const [isTasksDrawerOpen, setIsTasksDrawerOpen] = useState(false);

  useEffect(() => {
    !shouldShowTasksDrawer && setIsTasksDrawerOpen(false);
  }, [shouldShowTasksDrawer]);

  const [currentTask, setCurrentTask] = useAtom(activeTask);

  const { projectId, clientId } = useParams();

  const [deliverables, setDeliverables] = useState<null | Deliverable[]>(null);

  const [draggingElement, setDraggingElement] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const [editedDeliverable, setEditedDeliverable] = useState<Deliverable | null>(null);

  const getDeliverables = async ({ firstLoading = false, showLoading = false }: { firstLoading?: boolean, showLoading?: boolean }) => {
    try {
      showLoading && setLoading(true);
      const { response, statusCode } = await getTaskDeliverables(projectId!, currentTask?.id, firstLoading);
      if (statusCode === 200) {
        setDeliverables(response.data);
        if (firstLoading) {
          setCurrentTask({
            id: response.data[0].task.id,
            name: response.data[0].task.name,
            level: response.data[0].task.level,
            default: true,
          });
        }
      } else {
        throw new Error('Failed to fetch deliverables');
      }
    } catch (e) {
      notify({ text: { body: t(e.message) } });
      console.error(e.message);
    } finally {
      showLoading && setLoading(false);
    }
  };

  useEffect(() => {
    !currentTask && getDeliverables({ firstLoading: true });
  }, []);

  useEffect(() => {
    currentTask && !currentTask.default && getDeliverables({ showLoading: true });
  }, [currentTask]);

  useEffect(() => {
    currentTask && shouldShowTasksDrawer && setIsTasksDrawerOpen(false);
  }, [currentTask?.id]);

  const updateDeliverable = async (values: Record<string, any>, id?: number) => {
    try {
      const { statusCode } = await apiClient.put(`projects/${projectId}/deliverables/${id || editedDeliverable!.id}`, {
        body: JSON.stringify(values),
      });

      if (statusCode !== 200) throw new Error();
      getDeliverables({});
    } catch (e) {
      console.error(e);
      notify();
    } finally {
      setEditedDeliverable(null);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    const active: any = event.active.data.current;
    active && setDraggingElement(active);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setDeliverables((items) => {
        const oldIndex = items!.findIndex(deliverable => deliverable.id === active.id);
        const newIndex = items!.findIndex(deliverable => deliverable.id === over?.id);
        return arrayMove(items!, oldIndex, newIndex);
      });
      over?.data.current && active?.data.current
        && updateDeliverable(
          { order: over.data.current.deliverable.order },
          active.data.current.deliverable.id,
        );
    }

    setDraggingElement(null);
  };

  const addDeliverable = (newDeliverable: Deliverable) => {
    setDeliverables(prev => [...(prev || []), newDeliverable]);
  };

  const removeDeliverable = (id: number) => {
    let indexToRemove = deliverables?.findIndex(item => item.id === id);
    indexToRemove && deliverables && setDeliverables(prev => [...prev!.slice(0, indexToRemove), ...prev!.slice(indexToRemove! + 1)]);
  };

  const customDeliverables = deliverables?.filter(deliverable => deliverable.custom);

  const [isAddDeliverableDrawerOpen, setIsAddDeliverableDrawerOpen] = useState(false);
  const openEditForm = (deliverable: Deliverable) => {
    setEditedDeliverable(deliverable);
  };

  return (
    <div>
      <SubHeader
        fallbackLink={`d/client/${clientId}/project/${projectId}/summary`}
        title={t('Manage deliverables')}
      >
        <Button
          disabled={currentTask === null}
          onClick={() => setIsAddDeliverableDrawerOpen(true)}
          variant={ButtonVariants.SECONDARY}
          iconSize={{ width: 16, height: 16 }}
          className={styles.addDeliverableButton}
          icon={(
            <svg>
              <use
                xlinkHref={`${PlusSVG}#plusSVG`}
                href={`${PlusSVG}#plusSVG`}
              />
            </svg>
          )}
        >
          <span>{t('Add deliverable')}</span>
        </Button>
      </SubHeader>
      <div className={styles.content}>
        {!shouldShowTasksDrawer && <ManageDeliverablesTreelist />}

        <div className={styles.details}>
          {currentTask ? (
            <div className={styles.deliverables}>
              <header className={styles.deliverables__header}>
                <div>
                  <p className={styles.deliverables__subtitle}>{t('Deliverables')}</p>
                  <h2 className={styles.deliverables__title}>{currentTask?.name}</h2>
                </div>
                {shouldShowTasksDrawer && (
                  <Button
                    className={styles.selectTaskButton}
                    icon={(
                      <svg>
                        <use
                          xlinkHref={`${MenuSVG}#menuSVG`}
                          href={`${MenuSVG}#menuSVG`}
                        />
                      </svg>
                    )}
                    iconSize={{ width: 18, height: 18 }}
                    onClick={() => setIsTasksDrawerOpen(true)}
                  >
                    {t('Select task')}
                  </Button>
                )}
              </header>
              {deliverables?.length ? (
                <div>
                  <div className={classNames(styles.list, {
                    [styles.loading]: loading,
                  })}
                  >
                    {deliverables.filter(deliverable => !deliverable.custom).map(deliverable => (
                      <SortableDeliverable
                        key={deliverable.id}
                        deliverable={deliverable}
                        openEditForm={openEditForm}
                      />
                    ))}
                    {customDeliverables && (
                      <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                      >
                        <SortableContext
                          items={customDeliverables}
                          strategy={verticalListSortingStrategy}
                        >
                          {customDeliverables.map(deliverable => (
                            <SortableDeliverable
                              key={deliverable.id}
                              deliverable={deliverable}
                              removeDeliverable={removeDeliverable}
                              openEditForm={openEditForm}
                              custom
                            />
                          ))}
                        </SortableContext>
                        <DragOverlay>
                          {draggingElement ? (
                            <SortableDeliverable
                              deliverable={draggingElement.deliverable}
                              draggingElement={!!draggingElement}
                              removeDeliverable={removeDeliverable}
                              custom
                            />
                          ) : null}
                        </DragOverlay>
                      </DndContext>
                    )}
                  </div>
                </div>
              ) : (
                <div className={classNames(styles.list_empty, {
                  [styles.loading]: loading,
                })}
                >
                  <svg className={styles.emptyList__icon}>
                    <use
                      xlinkHref={`${EmptyListSVG}#emptyListSVG`}
                      href={`${EmptyListSVG}#emptyListSVG`}
                    />
                  </svg>
                  <p className={styles.list_empty__text}>{t('No deliverables assigned to this task')}</p>
                </div>
              )}
            </div>
          ) : (
            <div className={classNames(styles.list_empty, {
              [styles.loading]: loading,
            })}
            >
              <svg className={styles.emptyList__icon}>
                <use
                  xlinkHref={`${EmptyListSVG}#emptyListSVG`}
                  href={`${EmptyListSVG}#emptyListSVG`}
                />
              </svg>
              <p className={styles.list_empty__text}>{t('Choose the task to see its deliverables')}</p>
            </div>
          )}
        </div>
      </div>
      {shouldShowTasksDrawer && (
        <Drawer
          isOpen={isTasksDrawerOpen}
          setIsOpen={setIsTasksDrawerOpen}
          className={styles.tasksDrawer}
        >
          <ManageDeliverablesTreelist />
        </Drawer>
      )}
      <Drawer
        isOpen={isAddDeliverableDrawerOpen}
        setIsOpen={setIsAddDeliverableDrawerOpen}
        title={t('Add deliverable')}
      >
        <AddDeliverableForm
          addDeliverable={addDeliverable}
          closeForm={() => setIsAddDeliverableDrawerOpen(false)}
          lastOrder={deliverables?.length ? deliverables[deliverables.length - 1].order : 0}
        />
      </Drawer>
      {editedDeliverable && (
        <Drawer
          isOpen={!!editedDeliverable}
          setIsOpen={isOpen => setEditedDeliverable(isOpen ? editedDeliverable : null)}
          title={t('Edit deliverable')}
        >
          <EditDeliverableForm
            deliverableId={editedDeliverable.id}
            submit={updateDeliverable}
          />
        </Drawer>
      )}
    </div>
  );
};

export default ManageDeliverables;
