import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import useSWR, { mutate as globalMutate } from 'swr';
import apiClient from '../../../../../apiClient';
import { ReactComponent as PlusSVG } from '../../../../../public/media/plus.svg';
import { ReactComponent as StarsSVG } from '../../../../../public/media/stars.svg';
import { notify } from '../../../../../store/notifications.ts';
import EmptyList from '../../../../EmptyList/EmptyList';
import Loader from '../../../../Loader/Loader';
import Portal from '../../../../Portal.tsx';
import SubHeader from '../../../../SubHeader/SubHeader';
import Button, { ButtonVariants } from '../../../../UIKit/Button/Button';
import Drawer from '../../../../UIKit/Drawer/Drawer';
import AddRiskForm from './AddRiskForm/AddRiskForm';
import AIGeneratedRisks from './AIGeneratedRisks/AIGeneratedRisks';
import DeleteRiskModal from './DeleteRiskModal/DeleteRiskModal';
import EditRiskForm from './EditRiskForm/EditRiskForm';
import ProjectRisksList from './ProjectRisksList';
import ProjectRisksTable, { RiskItem } from './ProjectRisksTable/ProjectRisksTable';
import { IProjectRisk, ProjectRisksActionType } from './types';
import styles from './ProjectRisks.module.scss';
import { GENERAL_FEATURE_FLAG_KEY, isUserHasFeatureAccess } from '../../../../../actions/main.loader.ts';
import { ListResponse } from '../../../Users/UsersList/types.ts';

const generateRisks = async (projectId: string): Promise<IProjectRisk[]> => {
  try {
    const { statusCode, response } = await apiClient
      .get<{ data: IProjectRisk[], message?: string }>(`projects/${projectId}/generate-risks`);
    if (statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (e) {
    throw new Error(e);
  }
};

const ProjectRisks = () => {
  const { t, i18n } = useTranslation();
  const { projectId, clientId } = useParams();

  const [page, setPage] = useState(1);

  const { data: risksData, isLoading, mutate: mutateRisks } = useSWR(
    [`projects/${projectId}/risks?page=${page}`, i18n.language, 'risks'],
    ([url]) => apiClient
      .get<ListResponse<IProjectRisk>>(url).then(response => response.response),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    },
  );
  const risks = risksData?.data;
  const totalPages = risksData?.meta?.last_page || 0;

  const setRisks = (
    callback?: (currentRisks: ListResponse<IProjectRisk> | undefined) => ListResponse<IProjectRisk>,
  ) => globalMutate<ListResponse<IProjectRisk> | undefined>(
    (key: any[]) => key.includes('risks') && key.includes(`projects/${projectId}/risks?page=${page}`),
    (prev) => callback?.(prev),
    { revalidate: false },
  );

  const [generatedRisks, setGeneratedRisks] = useState<IProjectRisk[]>([]);
  const [isAddRiskDrawerOpen, setIsAddRiskDrawerOpen] = useState<boolean>(false);
  const [isEditRiskDrawerOpen, setIsEditRiskDrawerOpen] = useState<boolean>(false);
  const [isDeleteRiskModalOpen, setIsDeleteRiskModalOpen] = useState<boolean>(false);
  const [isAiGeneratedRisksOpen, setIsAiGeneratedRisksOpen] = useState<boolean>(false);

  const [selectedRisk, setSelectedRisk] = useState<IProjectRisk | null>(null);

  const onRiskActionHandler = (action: ProjectRisksActionType, risk: IProjectRisk): void => {
    setSelectedRisk(risk);

    if (action === ProjectRisksActionType.DELETE) setIsDeleteRiskModalOpen(true);
    if (action === ProjectRisksActionType.EDIT) setIsEditRiskDrawerOpen(true);
  };

  // AI Generated Risks
  const [savedRisks, setSavedRisks] = useState<RiskItem[]>([]);

  const [isSavingAiGeneratedRisks, setIsSavingAiGeneratedRisks] = useState<boolean>(false);

  const saveRisk = (risk: RiskItem): void => {
    setSavedRisks(prev => (
      [...prev, { ...risk, ...generatedRisks.find(riskItem => riskItem.id === risk.id) }]
    ));
  };

  const removeRiskFromSaved = (riskId: number) => {
    setSavedRisks(prev => (
      [...prev.filter(riskItem => riskItem.id !== riskId)]
    ));
  };

  const [showGenerateAIModal, setShowGenerateAIModal] = useState(false);

  useEffect(() => {
    if (showGenerateAIModal) {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }
  }, [showGenerateAIModal]);

  const saveAIGeneratedRisks = async () => {
    setIsSavingAiGeneratedRisks(true);
    try {
      const addedRisks = savedRisks.filter(risk => generatedRisks.find(riskItem => riskItem.id === risk.id));
      const { statusCode, response } = await apiClient.post<{ data: IProjectRisk[], message?: string }>(
        `projects/${projectId}/risks-bulk`,
        { body: JSON.stringify({ risks: addedRisks }) },
      );
      if (statusCode === 200) {
        setSavedRisks([]);
        setIsAiGeneratedRisksOpen(false);
        mutateRisks();
        setShowGenerateAIModal(false);
      } else {
        throw new Error(response.message);
      }
    } catch (e) {
      console.error(e.message);
      notify({ text: e.message ? { body: e.message } : {} });
    } finally {
      setIsSavingAiGeneratedRisks(false);
    }
  };

  // Manual Risks
  const onRiskAdd = (): void => {
    mutateRisks();
    setIsAddRiskDrawerOpen(false);
  };

  const onRiskEdit = (editedRisk: IProjectRisk): void => {
    // @ts-ignore
    setRisks((prev) => {
      if (prev) {
        const newRisks = prev?.data?.map(risk => (risk.id === editedRisk.id ? editedRisk : risk));
        return prev ? { ...prev, data: newRisks } : undefined;
      } else {
        return prev;
      }
    });
    setIsEditRiskDrawerOpen(false);
  };

  const onRiskDelete = (): void => {
    mutateRisks();
    setIsDeleteRiskModalOpen(false);
  };

  const [isExporting, setIsExporting] = useState<boolean>(false);

  const exportRisks = async (): Promise<void> => {
    setIsExporting(true);

    try {
      await apiClient.download(
        `projects/${projectId}/risks-export`,
        'xml',
        'risks.xlsx',
      );
    } catch (e) {
      console.error(e.message);
    } finally {
      setIsExporting(false);
    }
  };

  const isPageLoading: boolean = isLoading;
  const isMobileOrTabletDevice = useMediaQuery('(max-width: 1279px)');

  const [isRisksGenerating, setIsRisksGenerating] = useState(false);

  const isModalMode = true;

  const getGeneratedRisks = async (projectid: string, modalMode = false): Promise<void> => {
    try {
      setIsRisksGenerating(true);
      const data = await generateRisks(projectid);
      !modalMode && setIsAiGeneratedRisksOpen(true);
      setGeneratedRisks(data.map((item, index) => ({ ...item, id: index, manual: true })));
      modalMode && setShowGenerateAIModal(true);
    } catch (e) {
      console.error(e.message);
      notify({ text: e.message ? { body: e.message } : {} });
    } finally {
      setIsRisksGenerating(false);
    }
  };

  const handleBackdropClick = (event: any) => {
    const { target, currentTarget } = event;
    target === currentTarget && setShowGenerateAIModal(false);
  };

  return (
    <section className={styles.projectRisks}>
      <SubHeader
        fallbackLink={`d/client/${clientId}/project/${projectId}/summary`}
        className={classNames({
          [styles.collapsedSubheader]: isAiGeneratedRisksOpen,
        })}
        title={t('Manage risks')}
      >
        {isAiGeneratedRisksOpen && (
          <div className={classNames(styles.headerActions, styles.headerActions_ai)}>
            <Button
              type='button'
              variant={ButtonVariants.SECONDARY}
              onClick={() => setIsAiGeneratedRisksOpen(false)}
            >
              {t('Exit')}
            </Button>
            <Button
              type='button'
              variant={ButtonVariants.PRIMARY}
              onClick={saveAIGeneratedRisks}
              loading={isSavingAiGeneratedRisks}
              disabled={!savedRisks.length}
            >
              {t('Save & Proceed')}
            </Button>
          </div>
        )}
        {risks && (risks?.length > 0) && !isAiGeneratedRisksOpen && (
          <div className={styles.headerActions}>
            <Button
              type='button'
              variant={ButtonVariants.PRIMARY}
              onClick={exportRisks}
              loading={isExporting}
              disabled={isExporting}
            >
              {t('Export list')}
            </Button>
          </div>
        )}
      </SubHeader>
      <div className={classNames(styles.projectRisks__content, {
        [styles.projectRisks__content_empty]: risks && !risks.length && !isAiGeneratedRisksOpen,
        [styles.projectRisks__content_mobile]: isMobileOrTabletDevice,
      })}
      >

        {isPageLoading && (
          <div className={styles.loader}>
            <Loader size={32} />
          </div>
        )}

        {!isPageLoading && (risks?.length ? (
          <>
            <header className={styles.header}>
              <h5 className={styles.title}>
                {t('Added risks')}
              </h5>
              <div className={styles.header__buttons}>
                {isUserHasFeatureAccess(GENERAL_FEATURE_FLAG_KEY) && (
                  <Button
                    onClick={() => getGeneratedRisks(projectId!, isModalMode)}
                    iconSize={{ width: 18, height: 18 }}
                    type='button'
                    icon={<StarsSVG />}
                    variant={ButtonVariants.PRIMARY}
                    className={styles.button}
                    loading={isRisksGenerating}
                    disabled={isRisksGenerating}
                  >
                    {t('Add item with AI')}
                  </Button>
                )}
                <Button
                  iconSize={{ width: 12, height: 12 }}
                  icon={<PlusSVG />}
                  type='button'
                  variant={ButtonVariants.SECONDARY}
                  onClick={() => {
                    setIsAddRiskDrawerOpen(true);
                  }}
                >
                  {t('Add item manually')}
                </Button>
              </div>
            </header>

            {isMobileOrTabletDevice
              ? (
                <ProjectRisksList
                  onRiskActionHandler={onRiskActionHandler}
                  risks={risks}
                  pagination={{ page, setPage, totalPages }}
                />
              ) : (
                <ProjectRisksTable
                  onRiskActionHandler={onRiskActionHandler}
                  risks={risks}
                  isEditMode={false}
                  pagination={{ page, setPage, totalPages }}
                />
              )}
          </>
        ) : isAiGeneratedRisksOpen
          ? (
            <AIGeneratedRisks
              risksForSave={savedRisks}
              saveRisk={saveRisk}
              removeRiskFromSaved={removeRiskFromSaved}
              generatedRisks={generatedRisks}
            />
          )
          : (
            <div className={styles.projectRisks__emptyState}>
              <EmptyList title={t('You don’t have any risks recorded yet')} />
              <div className={styles.actions}>
                <Button
                  iconSize={{ width: 18, height: 18 }}
                  type='button'
                  icon={<PlusSVG />}
                  variant={ButtonVariants.SECONDARY}
                  className={styles.button}
                  onClick={() => setIsAddRiskDrawerOpen(true)}
                >
                  {t('Create risks manually')}
                </Button>
                {isUserHasFeatureAccess(GENERAL_FEATURE_FLAG_KEY) && (
                  <Button
                    onClick={() => getGeneratedRisks(projectId!)}
                    iconSize={{ width: 18, height: 18 }}
                    type='button'
                    icon={<StarsSVG />}
                    variant={ButtonVariants.PRIMARY}
                    className={styles.button}
                    loading={isRisksGenerating}
                    disabled={isRisksGenerating}
                  >
                    {t('Create AI Supported risks')}
                  </Button>
                )}
              </div>
            </div>
          ))}
      </div>
      <Drawer
        isOpen={isAddRiskDrawerOpen}
        setIsOpen={setIsAddRiskDrawerOpen}
        title={t('Add new item')}
        className={styles.drawer}
      >
        <AddRiskForm
          closeForm={() => setIsAddRiskDrawerOpen(false)}
          onRiskAdd={onRiskAdd}
        />
      </Drawer>

      <Drawer
        isOpen={isEditRiskDrawerOpen}
        setIsOpen={setIsEditRiskDrawerOpen}
        title={t('Edit risk')}
        className={styles.drawer}
      >
        <EditRiskForm
          riskData={selectedRisk as IProjectRisk}
          closeForm={() => {
            setIsEditRiskDrawerOpen(false);
            setSelectedRisk(null);
          }}
          onRiskEdit={onRiskEdit}
        />
      </Drawer>

      {isDeleteRiskModalOpen && (
        <DeleteRiskModal
          closeModal={() => {
            setIsDeleteRiskModalOpen(false);
            setSelectedRisk(null);
          }}
          risk={selectedRisk as IProjectRisk}
          onRiskDelete={onRiskDelete}
        />
      )}

      {showGenerateAIModal && (
        <Portal>
          <div
            className={styles.aiGeneratedModal__wrap}
            role='button'
            tabIndex={0}
            onMouseDown={handleBackdropClick}
          >
            <div className={styles.aiGeneratedModal}>
              <header className={styles.aiGeneratedModal__header}>
                <h4 className={styles.aiGeneratedModal__title}>{t('Add item with AI support')}</h4>
                <div className={styles.aiGeneratedModal__header__actions}>
                  <Button
                    type='button'
                    variant={ButtonVariants.SECONDARY}
                    onClick={() => setShowGenerateAIModal(false)}
                  >
                    {t('Exit')}
                  </Button>
                  <Button
                    type='button'
                    variant={ButtonVariants.PRIMARY}
                    onClick={saveAIGeneratedRisks}
                    loading={isSavingAiGeneratedRisks}
                    disabled={!savedRisks.length}
                  >
                    {t('Save')}
                  </Button>
                </div>
              </header>
              <div className={styles.aiGeneratedModal__content}>
                <AIGeneratedRisks
                  risksForSave={savedRisks}
                  saveRisk={saveRisk}
                  removeRiskFromSaved={removeRiskFromSaved}
                  generatedRisks={generatedRisks}
                />
              </div>
            </div>
          </div>
        </Portal>
      )}
    </section>
  );
};

export default ProjectRisks;
