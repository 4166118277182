import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import Input from '../UIKit/Input/Input.tsx';
import styles from './DeliverableLinkForm.module.scss';
import Button, { ButtonVariants } from '../UIKit/Button/Button.tsx';
import { DeliverableLink } from '../pages/NewProject/NewProjectDeliverables/types.ts';
import DeleteSVG from '../../public/media/delete-icon.svg';

type DeliverableLinkFormProps = {
  createLink: (values: FormikValues) => void,
  editLink: (values: FormikValues, id: number) => void,
  activeLink: DeliverableLink | null,
  deleteLink: () => void,
};

const DeliverableLinkForm = ({
  createLink, editLink, activeLink, deleteLink,
}: DeliverableLinkFormProps) => {
  const { t } = useTranslation();

  const {
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    handleBlur,
    touched,
    errors,
    setValues,
  }: FormikValues = useFormik({
    initialValues: {
      caption: '',
      link: '',
    },
    validationSchema: Yup.object({
      caption: Yup.string()
        .trim()
        .max(100, t('Must be 100 characters at most'))
        .required(t('Name is required')),
      link: Yup.string()
        .trim()
        .matches(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
          t('Link is not valid'),
        )
        .required(t('Link is required')),
    }),
    onSubmit: (submitValues: FormikValues) => {
      activeLink ? editLink(submitValues, activeLink.id) : createLink(submitValues);
    },
  });

  useEffect(() => {
    if (!activeLink) return;

    setValues(activeLink);
  }, [activeLink]);

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.form__block}>
        <Input
          value={values.caption}
          setValue={handleChange}
          onBlur={handleBlur}
          id='caption'
          label={t('Name of the document')}
          name='caption'
          error={!!(touched.caption && errors.caption)}
          errorMessage={errors.caption}
        />
        <Input
          value={values.link}
          setValue={handleChange}
          onBlur={handleBlur}
          id='link'
          label={t('Link to the document')}
          name='link'
          error={!!(touched.link && errors.link)}
          errorMessage={errors.link}
        />
      </div>
      <div className={styles.form__footer}>
        {activeLink && (
          <Button
            type='button'
            variant={ButtonVariants.SECONDARY}
            onClick={deleteLink}
            icon={(
              <svg>
                <use
                  xlinkHref={`${DeleteSVG}#deleteSVG`}
                  href={`${DeleteSVG}#deleteSVG`}
                />
              </svg>
            )}
            iconSize={{ width: 18, height: 18 }}
          >
            {t('Delete attached link')}
          </Button>
        )}
        <Button
          type='submit'
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {activeLink ? t('Save changes') : t('Save new link')}
        </Button>
      </div>
    </form>
  );
};

export default DeliverableLinkForm;
