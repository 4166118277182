import dayjs from 'dayjs';
import { useAtom } from 'jotai/index';
import classnames from 'classnames';
import { userAtom } from '../../../store/auth.ts';
import { TaskResource } from '../../pages/NewProject/NewProjectSummary/types.ts';
import styles from '../ProjectTracking.module.scss';
import { DEFAULT_DATE_FORMAT } from '../../../constants.ts';

export const DurationCell = ({ task, className }: { task: TaskResource, className?: string }) => {
  const userData = useAtom(userAtom);
  const user = userData[0]?.user;
  const format = user?.dateFormat || DEFAULT_DATE_FORMAT;
  const startDate = dayjs(task.start_date).format(format);
  const endDate = dayjs(task.finish_date).format(format);
  const date = `${startDate} - ${endDate}`;

  return (
    <div className={classnames(styles.durationCell, className)}>
      <p>{date}</p>
    </div>
  );
};
