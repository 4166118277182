import React from 'react';
import {
  Pagination as MuiPagination, PaginationItem, useMediaQuery,
} from '@mui/material';

import { ReactComponent as DoubleArrowLeftSVG } from '../../../public/media/double-angle-left.svg';

import styles from './Pagination.module.scss';

type PaginationPropsType = {
  page: number;
  setPage: (pageNumber: number) => void;
  totalPages: number;
};

const Pagination = ({ page, setPage, totalPages }: PaginationPropsType) => {
  const isMobileDevice = useMediaQuery('(max-width: 767px)');

  if (totalPages > 1) {
    return (
      <MuiPagination
        color='primary'
        showFirstButton
        showLastButton
        page={page}
        siblingCount={isMobileDevice ? 0 : 1}
        boundaryCount={1}
        count={totalPages}
        className={styles.pagination}
        onChange={(e, pageNumber) => {
          setPage(pageNumber);
        }}
        renderItem={(item) => (
          <PaginationItem
            className={styles.pagination__item}
            components={{ first: DoubleArrowLeftSVG, last: DoubleArrowLeftSVG }}
            {...item}
          />
        )}
      />
    );
  }
  return null;
};

export default Pagination;
