import { JiraStatus } from './interfaces.ts';
import apiClient from '../../apiClient.ts';
import { TaskResource } from '../pages/NewProject/NewProjectSummary/types.ts';

export type UpdateError = { message: string };
export type UpdatedTask = { data: TaskResource };

export const updateTask = async (
  projectId: string,
  taskId: number,
  body: { status: JiraStatus, reset_status?: boolean },
): Promise<UpdateError | UpdatedTask> => {
  try {
    const { statusCode, response } = await apiClient.put<UpdateError | UpdatedTask>(
      `projects/${projectId}/tasks/${taskId}`,
      { body: JSON.stringify(body) },
    );
    if (statusCode === 200) {
      return response;
    } else {
      throw new Error((response as UpdateError).message);
    }
  } catch (e) {
    console.error(e.message);
    throw new Error(e);
  }
};
