import { useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import Button, { ButtonIconPosition, ButtonVariants } from '../../UIKit/Button/Button.tsx';
import Popover, { PopoverPlacement } from '../../UIKit/Popover/Popover.tsx';
import { editLinkAtom } from '../projectTracking.atom.ts';
import Links from '../DropdownDisplay/Links/Links.tsx';
import Menu from '../DropdownDisplay/Menu/Menu.tsx';
import {
  DESKTOP_SMALL_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
  MOBILE_SMALL_MEDIA_QUERY,
  TABLET_LANDSCAPE_MEDIA_QUERY,
  TABLET_PORTRAIT_MEDIA_QUERY,
} from '../../../constants.ts';
import AngleDownSVG from '../../../public/media/angle-down.svg';
import AttachSVG from '../../../public/media/attach.svg';
import EditSVG from '../../../public/media/edit-icon.svg';
import DetailsSVG from '../../../public/media/details.svg';
import { TaskResource } from '../../pages/NewProject/NewProjectSummary/types.ts';
import styles from '../ProjectTracking.module.scss';
import { DropdownDisplay } from '../interfaces.ts';

type Link = {
  id: number,
  caption: string,
  link: string,
};

interface CustomCellTemplateData {
  deleteLink: (linkId: number, taskId: number) => void;
  task: TaskResource;
  resetToJira: (id: number) => Promise<void>,
  canResetToJira?: boolean,
  hasJiraIntegration?: boolean,
  isReadOnlyProjectAccess?: boolean,
}

export const AttachmentsCell = ({
  task, deleteLink, resetToJira, canResetToJira = true, hasJiraIntegration, isReadOnlyProjectAccess,
}: CustomCellTemplateData) => {
  const { t } = useTranslation();
  const setLinkAction = useSetAtom(editLinkAtom);

  const [deletedLink, setDeletedLink] = useState<Link | null>(null);

  const defaultDropdownStateDisplayDependingJira = hasJiraIntegration ? DropdownDisplay.MENU : DropdownDisplay.LINKS;

  const [displayOnDropdown, setDisplayOnDropdown] = useState<DropdownDisplay>(defaultDropdownStateDisplayDependingJira);
  const showOnDropdown = (dropdownItem: DropdownDisplay): void => {
    setDisplayOnDropdown(dropdownItem);
  };
  const resetDisplayOnDropdown = () => {
    showOnDropdown(DropdownDisplay.MENU);
  };

  const [closePopover, setClosePopover] = useState<VoidFunction>(() => (() => {}));
  const setClosePopoverCallback = (func: VoidFunction) => {
    setClosePopover(() => func);
  };

  const onLinkDeleting = (link: Link) => {
    setDeletedLink(deletedLink?.id === link.id ? null : link);
  };

  const confirmDeleting = (link: Link) => {
    deleteLink(link.id, task.id);
  };

  const isDesktopSmallDevice = useMediaQuery(DESKTOP_SMALL_MEDIA_QUERY);
  const isTabletLandscapeDevice = useMediaQuery(TABLET_LANDSCAPE_MEDIA_QUERY);
  const isTabletPortraitDevice = useMediaQuery(TABLET_PORTRAIT_MEDIA_QUERY);
  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);
  const isMobileSmallDevice = useMediaQuery(MOBILE_SMALL_MEDIA_QUERY);

  const numberOfLinksToRender = (isTabletLandscapeDevice || isTabletPortraitDevice || isMobileDevice || isMobileSmallDevice)
    ? 0 : isDesktopSmallDevice ? 1 : 2;

  const handleCloseTabletPortraitMobilePopover = (): void => {
    setTimeout(() => {
      setDeletedLink(null);
      hasJiraIntegration && resetDisplayOnDropdown();
    }, 300);
  };

  return (
    <div className={styles.attachmentsCell}>
      <div className={styles.attachedLinks}>
        {(isTabletPortraitDevice || isMobileDevice || isMobileSmallDevice)
          ? null : (task.links.length <= numberOfLinksToRender ? task.links.map((link: Link) => (
            <div
              className={styles.attachedLink}
              key={link.id}
            >
              <a
                target='_blank'
                className={styles.attachedLink__link}
                href={link.link}
                rel='noreferrer'
              >
                {link.caption}
              </a>
              {!isReadOnlyProjectAccess && (
              <button
                aria-label={t('Edit attached link')}
                type='button'
                className={styles.attachedLink__editButton}
                onClick={() => {
                  setLinkAction({ link, task: { id: task.id, title: task.name } });
                  closePopover();
                }}
              >
                <svg>
                  <use
                    xlinkHref={`${EditSVG}#editSVG`}
                    href={`${EditSVG}#editSVG`}
                  />
                </svg>
              </button>
              )}
            </div>
          )) : (
            <Popover
              paperClassName={styles.attachedLinksDropdown}
              placement={PopoverPlacement.CONTEXT_MENU}
              onClose={() => setTimeout(() => { setDeletedLink(null); }, 300)}
              triggerButton={(
                <div className={styles.attachedLinksDropdown__button}>
                  <div className={styles.attachedLinksDropdown__button__content}>
                    {t('Links')}
                    {' '}
                    <sup>
                      (
                      {task.links.length}
                      )
                    </sup>
                  </div>
                  <svg className={styles.angleSVG}>
                    <use
                      xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                      href={`${AngleDownSVG}#angleDownSVG`}
                    />
                  </svg>
                </div>
              )}
              closeCallback={setClosePopoverCallback}
            >
              <Links
                task={task}
                deletedLink={deletedLink}
                onLinkDeleting={onLinkDeleting}
                confirmDeleting={confirmDeleting}
                closePopover={closePopover}
                isReadOnlyProjectAccess={isReadOnlyProjectAccess}
              />
            </Popover>
          ))}
      </div>
      {(isTabletPortraitDevice || isMobileDevice || isMobileSmallDevice) ? (
        <Popover
          paperClassName={(
            displayOnDropdown === DropdownDisplay.LINKS
              ? styles.attachedLinksDropdown
              : styles.mobileTabletActionsDropdown
          )}
          placement={PopoverPlacement.CONTEXT_MENU}
          onClose={handleCloseTabletPortraitMobilePopover}
          triggerButton={(
            <Button
              className={styles.attachButtonWithoutText}
              variant={ButtonVariants.SECONDARY}
              icon={(
                <svg>
                  <use
                    xlinkHref={`${DetailsSVG}#detailsSVG`}
                    href={`${DetailsSVG}#detailsSVG`}
                  />
                </svg>
              )}
              iconPosition={ButtonIconPosition.CENTER}
              iconSize={{ width: 16, height: 16 }}
            />
          )}
          closeCallback={setClosePopoverCallback}
        >
          {(displayOnDropdown !== DropdownDisplay.MENU && hasJiraIntegration) && (
            <div className={styles.buttonWrapper}>
              <button
                className={styles.backButton}
                type='button'
                onClick={resetDisplayOnDropdown}
                aria-label='Back'
              >
                <div className={styles.backButtonWrapper}>
                  <svg
                    width={16}
                    height={16}
                    className={styles.backButtonIcon}
                  >
                    <use
                      xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                      href={`${AngleDownSVG}#angleDownSVG`}
                    />
                  </svg>
                  <span>{t('Back')}</span>
                </div>
              </button>
            </div>
          )}
          {displayOnDropdown === DropdownDisplay.MENU && (
            <Menu
              jiraLink={task?.jira_link}
              canResetToJira={canResetToJira}
              showOnDropdown={showOnDropdown}
              linksCount={task.links.length}
              isReadOnlyProjectAccess={isReadOnlyProjectAccess}
            />
          )}
          {displayOnDropdown === DropdownDisplay.RESET_TO_JIRA_STATUS
            && canResetToJira && (
              <div className={styles.resetToJiraMobileSection}>
                <p>{t('Do you want to synchronize your status with Jira?')}</p>
                <Button
                  variant={ButtonVariants.PRIMARY}
                  className={styles.cancelButton}
                  onClick={resetDisplayOnDropdown}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  variant={ButtonVariants.SECONDARY}
                  className={styles.yesButton}
                  onClick={() => {
                    resetToJira(task.id);
                    closePopover();
                  }}
                >
                  {t('Yes, reset to Jira')}
                </Button>
              </div>
          )}
          {displayOnDropdown === DropdownDisplay.LINKS && (
            <Links
              task={task}
              deletedLink={deletedLink}
              onLinkDeleting={onLinkDeleting}
              confirmDeleting={confirmDeleting}
              closePopover={closePopover}
              isReadOnlyProjectAccess={isReadOnlyProjectAccess}
            />
          )}
        </Popover>
      ) : isTabletLandscapeDevice && !isReadOnlyProjectAccess ? (
        <Button
          onClick={() => setLinkAction({ link: null, task: { id: task.id, title: task.name } })}
          className={styles.attachButtonWithoutText}
          variant={ButtonVariants.SECONDARY}
          icon={(
            <svg>
              <use
                xlinkHref={`${AttachSVG}#attachSVG`}
                href={`${AttachSVG}#attachSVG`}
              />
            </svg>
          )}
          iconPosition={ButtonIconPosition.CENTER}
          iconSize={{ width: 16, height: 16 }}
        />
      ) : !isReadOnlyProjectAccess && (
        <Button
          onClick={() => setLinkAction({ link: null, task: { id: task.id, title: task.name } })}
          className={styles.attachButton}
          variant={ButtonVariants.SECONDARY}
          icon={(
            <svg>
              <use
                xlinkHref={`${AttachSVG}#attachSVG`}
                href={`${AttachSVG}#attachSVG`}
              />
            </svg>
          )}
          iconSize={{ width: 16, height: 16 }}
        >
          {t('Attach link')}
        </Button>
      )}
    </div>
  );
};
