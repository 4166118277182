export enum JiraStatus {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  DONE = 2,
  LOADING = 99, // this is a custom status for loading state, not a real Jira status
}

export enum DropdownDisplay {
  MENU = 'menu',
  VIEW_IN_JIRA = 'view in jira',
  RESET_TO_JIRA_STATUS = 'reset to jira status',
  LINKS = 'links',
}
