import { Deliverable, DeliverableStatus } from '../NewProjectDeliverables/types';

export type TaskResource = {
  id: number;
  name: string;
  duration: number;
  start: number;
  finish: number;
  parent_id: number | null;
  is_long_running: boolean;
  is_consist_of_multiple_sprints: boolean;
  icon: string;
  is_domain: boolean;
  start_date: string;
  finish_date: string;
  min_duration: number;
  task_at_risk: false;
  parent: null | number,
  links: Array<{
    id: number,
    caption: string,
    link: string,
  }>,
  status: {
    value: number,
    caption: string,
  },
  manual_status: boolean,
  jira_link: string,
  attached_link: null,
  last_history: {
    id: number,
    data: string,
    created_at: string,
  },
  has_children: boolean;
};

export type FormattedDeliverables = {
  domains: Record<number, { id: number; caption: string; deliverables: Deliverable[] }>;
  internal: number;
  external: number;
  total: number;
  rowData: Deliverable[];
};

export type EnumToStringValues<T extends { [index: string]: string | number }> = T[keyof T];

export type DeliverablesFilterValue = EnumToStringValues<typeof DeliverableStatus> | 'all';

export type SkillResource = {
  id: number;
  deliverables_count: number;
  caption: string;
};

export type StakeholderResource = {
  id: number;
  caption: string;
  date: string | null;
};

export type ToolIntegrationResource = {
  id: number | null,
  type: {
    value: Tool,
    caption: string,
    icon: string,
  },
  host: string | null,
  username: string | null,
  access_token: string | null
};

export enum ProjectJiraIntegrationStatus {
  NEW = 0,
  PENDING = 1,
  CREATED = 2,
  TASKS_SYNC = 3,
  FAILED_SYNC = 4,
}

export enum Tool {
  JIRA = 1,
}
