import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { debounce } from '@mui/material';

import SubHeader from '../SubHeader/SubHeader.tsx';
import PortfolioGantt from './PortfolioGantt/PortfolioGantt.tsx';
import Loader from '../Loader/Loader.tsx';
import { useRoutePrefix } from '../../usePermission.ts';

import ganttStyles from './PortfolioGantt/PortfolioGantt.module.scss';
import styles from './Portfolio.module.scss';

const Portfolio = () => {
  const { t } = useTranslation();
  const prefix = useRoutePrefix();
  const { clientId, id } = useParams();

  const currentClientId = clientId || id;

  // We encountered an issue with the Gantt chart component and the list of
  // dependent domains when resizing or changing screen orientation.
  // Repainting or updating the Gantt chart by the devextreme lib native methods did not resolve the problem.
  // The proposed solution was to unmount and remount the Portfolio component
  // after these events
  const [isResizing, setisResizing] = useState(false);
  const screenResize = debounce(() => {
    setisResizing(true);
    setTimeout(() => {
      setisResizing(false);
    }, 500);
  }, 1000);

  useEffect(
    () => {
      window.addEventListener('resize', screenResize);
      window.addEventListener('orientationchange', screenResize);

      return () => {
        window.removeEventListener('resize', screenResize);
        window.removeEventListener('orientationchange', screenResize);
      };
    },
    [],
  );
  // END

  return (
    <main className={styles.portfolio}>
      <SubHeader
        title={t('Project portfolio')}
        fallbackLink={`${prefix}/client/${currentClientId}/projects`}
      />
      <div className={styles.content}>
        <div className={styles.portfolio}>
          {isResizing
            ? <div className={ganttStyles.preloader}><Loader /></div>
            : <PortfolioGantt />}
        </div>
      </div>
    </main>
  );
};

export default Portfolio;
