// TODO: Refactor types
import { useAtom } from 'jotai/index';
import React, {
  forwardRef, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import useSWR, { mutate } from 'swr';
import {
  Accordion, AccordionDetails, AccordionSummary, useMediaQuery,
} from '@mui/material';
import { useLoaderData, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  NotificationOptionView, NotificationPlacement, NotificationPopupIcon, notify, removeNotification,
} from '../../store/notifications.ts';
import DeliverableLinkForm from '../DeliverableLinkForm/DeliverableLinkForm.tsx';
import { DeliverableLink } from '../pages/NewProject/NewProjectDeliverables/types.ts';
import SubHeader from '../SubHeader/SubHeader.tsx';
import Button, { ButtonIconPosition, ButtonVariants } from '../UIKit/Button/Button.tsx';
import Drawer from '../UIKit/Drawer/Drawer.tsx';
import { editLinkAtom } from './projectTracking.atom.ts';
import styles from './ProjectTracking.module.scss';
import apiClient from '../../apiClient.ts';
import { useDebounce } from '../../useDebounce.ts';
import Loader from '../Loader/Loader.tsx';
import { ProjectJiraIntegrationStatus, TaskResource } from '../pages/NewProject/NewProjectSummary/types.ts';

import SearchSVG from '../../public/media/search.svg';
import AngleDownSVG from '../../public/media/angle-down.svg';
import SpinnerSVG from '../../public/media/spinner-button.svg';
import JiraSVG from '../../public/media/jira.svg';
import ExcelSVG from '../../public/media/excel-icon.svg';
import NoTaskFoundSVG from '../../public/media/no-task-found.svg';
import { AttachmentsCell } from './ProjectTrackingCells/AttachmentsCell.tsx';
import { DurationCell } from './ProjectTrackingCells/DurationCell.tsx';
import { GetTaskError, GetTaskTask, StatusCell } from './ProjectTrackingCells/StatusCell.tsx';
import { StatusUpdateCell } from './ProjectTrackingCells/StatusUpdateCell.tsx';
import { TaskCell } from './ProjectTrackingCells/TaskCell.tsx';
import PlusSVG from '../../public/media/plus.svg';
import MinusSVG from '../../public/media/minus.svg';
import { MOBILE_MEDIA_QUERY } from '../../constants.ts';
import { JiraStatus } from './interfaces.ts';
import { UpdatedTask, updateTask } from './api.ts';
import { ProjectPermissions } from '../pages/Login/user.props.ts';
import { ProjectResource } from '../pages/Projects/types.ts';
import { useRoutePrefix } from '../../usePermission.ts';

interface GroupedItem extends TaskResource {
  children: GroupedItem[];
}

function groupItemsByIdAndParentId(items: TaskResource[]): GroupedItem[] {
  const itemMap = items.reduce((acc, item) => {
    if (item.id !== null) {
      acc[item.id] = { ...item, children: [] };
    }
    return acc;
  }, {} as { [key: number | string]: GroupedItem });

  const result: GroupedItem[] = [];

  items.forEach((item) => {
    if (item.parent_id === null && item.id) {
      result.push(itemMap[item.id]);
    } else if (item.parent_id && item.id && itemMap[item.parent_id]) {
      itemMap[item.parent_id].children.push(itemMap[item.id]);
    }
  });

  return result;
}

function filterItemsByName(items: GroupedItem[], searchTerm: string): GroupedItem[] {
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return items
    .map((item) => {
      const filteredChildren = filterItemsByName(item.children, lowerCaseSearchTerm);

      if (item.name.toLowerCase().includes(lowerCaseSearchTerm) || filteredChildren.length > 0) {
        return { ...item, children: filteredChildren };
      }

      return null;
    })
    .filter((item) => item !== null) as GroupedItem[];
}

const getTask = async (taskId: number, projectId: string) => {
  try {
    const { statusCode, response } = await apiClient.get<GetTaskError | GetTaskTask>(`projects/${projectId}/tasks/${taskId}`);

    if (statusCode !== 200) {
      throw new Error((response as GetTaskError).message);
    }
    return response;
  } catch (e) {
    console.error(e.message);
  }
};

const TaskRow = ({
  task,
  updateTasks,
  deleteLink,
  hasJiraIntegration,
  isReadOnlyProjectAccess,
  jiraIntegrationFailed,
}: {
  task: GroupedItem;
  updateTasks: (task: TaskResource, isStatusChange?: boolean) => void;
  deleteLink: (id: number, taskId: number) => void;
  hasJiraIntegration?: boolean;
  isReadOnlyProjectAccess?: boolean;
  jiraIntegrationFailed?: boolean;
}) => {
  const { t } = useTranslation();

  const [
    shouldShowAdditionalSection,
    setShouldShowAdditionalSection,
  ] = useState<boolean>(false);

  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);
  useEffect(() => {
    !isMobileDevice && setShouldShowAdditionalSection(false);
  }, [isMobileDevice]);

  const toggleShowLastStatusMobile = (): void => {
    setShouldShowAdditionalSection((v) => !v);
  };

  const { projectId } = useParams();
  const resetToJira = async (id: number): Promise<void> => {
    try {
      const { data: updatedTask } = await updateTask(projectId!, id, { reset_status: true, status: JiraStatus.NOT_STARTED }) as UpdatedTask;
      updateTasks(updatedTask, true);
      // trigger revalidation but keep prev data
      await mutate(
        (key: any[]) => key.includes('schedule'),
        undefined,
        {
          revalidate: true,
          populateCache: false,
        },
      );
    } catch (e) {
      notify({ text: { body: e?.message ?? '', title: t('An error occurred on reset to Jira status') } });
    }
  };

  const manualStatus = task.manual_status;

  const readOnly = task.has_children;
  const canResetToJira = manualStatus && !readOnly;

  return (
    <div className={classNames(styles.table__rowWrapper, {
      [styles.withOpenedSection]: shouldShowAdditionalSection,
    })}
    >
      <div
        className={styles.table__row}
        id={String(task.id)}
      >
        <TaskCell
          task={task}
          hasJiraIntegration={hasJiraIntegration}
        />
        <DurationCell
          task={task}
          className={styles.hideOnMobile}
        />
        <StatusCell
          task={task}
          updateTasks={updateTasks}
          className={classNames(styles.hideOnMobile, styles.hideOnTabletPortrait)}
          resetToJira={resetToJira}
          hasJiraIntegration={hasJiraIntegration}
          canResetToJira={canResetToJira}
          isReadOnlyProjectAccess={isReadOnlyProjectAccess}
          getTask={getTask}
          jiraIntegrationFailed={jiraIntegrationFailed}
        />
        <StatusUpdateCell
          task={task}
          className={classNames(styles.hideOnMobile, styles.hideOnTabletPortrait)}
        />
        <AttachmentsCell
          deleteLink={deleteLink}
          task={task}
          resetToJira={resetToJira}
          hasJiraIntegration={hasJiraIntegration}
          canResetToJira={canResetToJira}
          isReadOnlyProjectAccess={isReadOnlyProjectAccess}
        />
        <Button
          variant={ButtonVariants.SECONDARY}
          onClick={toggleShowLastStatusMobile}
          className={classNames(styles.hideNotMobileOrTabletPortrait, styles.showHideLastStatusUpdateMobileButton)}
          icon={(
            <svg>
              {shouldShowAdditionalSection ? (
                <use
                  xlinkHref={`${MinusSVG}#minusSVG`}
                  href={`${MinusSVG}#minusSVG`}
                />
              ) : (
                <use
                  xlinkHref={`${PlusSVG}#plusSVG`}
                  href={`${PlusSVG}#plusSVG`}
                />
              )}
            </svg>
          )}
          iconSize={{ width: 16, height: 16 }}
          iconPosition={ButtonIconPosition.CENTER}
        />
      </div>
      {shouldShowAdditionalSection && (
        <div className={styles.additionalSection}>
          <div className={styles.additionalSectionItem}>
            <p className={styles.statusLabel}>{t('Status')}</p>
            <StatusCell
              task={task}
              updateTasks={updateTasks}
              isReadOnlyProjectAccess={isReadOnlyProjectAccess}
              getTask={getTask}
              jiraIntegrationFailed={jiraIntegrationFailed}
            />
          </div>
          {!!task.last_history && (
            <StatusUpdateCell
              task={task}
              className={styles.additionalSectionItem}
            />
          )}
        </div>
      )}
    </div>
  );
};

type ScheduleAccordionProps = {
  task: GroupedItem;
  updateTasks: (task: TaskResource) => void;
  deleteLink: (id: number, taskId: number) => void;
  hasJiraIntegration?: boolean;
  isReadOnlyProjectAccess?: boolean;
  jiraIntegrationFailed?: boolean;
};

const ScheduleAccordion = forwardRef<HTMLDivElement, ScheduleAccordionProps>(
  ({
    task,
    updateTasks,
    deleteLink,
    hasJiraIntegration,
    isReadOnlyProjectAccess,
    jiraIntegrationFailed,
  }, ref) => {
    const [expanded, setExpanded] = useState<boolean>(true);

    const handleChange = () => {
      setExpanded(prev => !prev);
    };

    return (task.children.length > 0 ? (
      <Accordion
        defaultExpanded
        ref={ref}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={(
            <button
              type='button'
              onClick={handleChange}
            >
              <svg
                className={styles.table__row__expandArrow}
              >
                <use
                  xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                  href={`${AngleDownSVG}#angleDownSVG`}
                />
              </svg>
            </button>
          )}
          aria-controls='panel-content'
          id='panel-header'
        >
          <TaskRow
            task={task}
            updateTasks={updateTasks}
            deleteLink={deleteLink}
            hasJiraIntegration={hasJiraIntegration}
            isReadOnlyProjectAccess={isReadOnlyProjectAccess}
            jiraIntegrationFailed={jiraIntegrationFailed}
          />
        </AccordionSummary>
        {task.children && task.children.length > 0 ? (
          <AccordionDetails>
            <div className={styles.tableAccordionWrapper}>
              {task.children.map((child) => (
                <ScheduleAccordion
                  key={child.id}
                  task={child}
                  updateTasks={updateTasks}
                  deleteLink={deleteLink}
                  hasJiraIntegration={hasJiraIntegration}
                  isReadOnlyProjectAccess={isReadOnlyProjectAccess}
                  jiraIntegrationFailed={jiraIntegrationFailed}
                />
              ))}
            </div>
          </AccordionDetails>
        ) : (
          <TaskRow
            updateTasks={updateTasks}
            task={task}
            deleteLink={deleteLink}
            hasJiraIntegration={hasJiraIntegration}
            isReadOnlyProjectAccess={isReadOnlyProjectAccess}
            jiraIntegrationFailed={jiraIntegrationFailed}
          />
        )}
      </Accordion>
    ) : (
      <TaskRow
        updateTasks={updateTasks}
        task={task}
        deleteLink={deleteLink}
        hasJiraIntegration={hasJiraIntegration}
        isReadOnlyProjectAccess={isReadOnlyProjectAccess}
        jiraIntegrationFailed={jiraIntegrationFailed}
      />
    ));
  },
);

const showPedningNotification = (t: TFunction<'translation', undefined>): string => notify({
  text: {
    title: t('Tasks importing in progress'),
    body: t('Syncing tasks with Jira takes some time. Please be patient'),
  },
  duration: undefined,
  placement: NotificationPlacement.BOTTOM,
  type: NotificationOptionView.INFO_POPUP,
  icon: NotificationPopupIcon.PENDING,
  withoutCloseButton: true,
});

const showSuccessNotification = (t: TFunction<'translation', undefined>): string => notify({
  text: {
    title: t('Tasks updating is finished!'),
    body: t('All the tasks synced with Jira successfully!'),
  },
  duration: 15,
  placement: NotificationPlacement.BOTTOM,
  icon: NotificationPopupIcon.SUCCESS,
  type: NotificationOptionView.INFO_POPUP,
});

type JiraErrorType = {
  errors: Array<string>;
  statusCode: number;
};

type DefaultErrorType = {
  message: string;
};

const ProjectTracking = () => {
  const { projectId, clientId } = useParams();
  const project = useLoaderData() as { data: ProjectResource };

  const [notifications, setNotifications] = useState<Array<string>>([]);

  const { t, i18n } = useTranslation();

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [linkAction, setLinkAction] = useAtom(editLinkAtom);
  const [isDownloadPending, setIsDownloadPending] = useState(false);
  const isInitialization = useRef<boolean>(true);

  const hasJiraIntegration = project?.data?.jiraIntegration !== null;
  const isJiraIntegrationSuccessfullyFinished = project?.data?.jiraIntegration?.status.value === ProjectJiraIntegrationStatus.TASKS_SYNC;
  const isJiraSyncFailed = project?.data?.jiraIntegration?.status.value === ProjectJiraIntegrationStatus.FAILED_SYNC;
  const [isJiraResponseSuccessful, setIsJiraResponseSuccessful] = useState<boolean>(false);

  const permissions = project?.data?.permissions;
  const isAbleToEdit = permissions?.includes(ProjectPermissions.UPDATE);
  const isReadOnlyProjectAccess = !isAbleToEdit && permissions?.includes(ProjectPermissions.VIEW);

  const prefix = useRoutePrefix();

  useEffect(() => {
    project.data.jiraIntegration?.status.value === ProjectJiraIntegrationStatus.FAILED_SYNC
      && notify({
        text: {
          title: t('An error occurred on syncing tasks with Jira'),
          body: t('Some Jira functionality may be unavailable. Please try re-syncing tasks with Jira on the Project summary page.'),
        },
        duration: undefined,
        placement: NotificationPlacement.TOP,
      });
  }, [project]);

  const {
    data: loadedTasks,
    // mutate,
    isValidating, isLoading,
  } = useSWR(
    [`projects/${projectId}/tracking-overview/tasks?page=all`, i18n.language, 'schedule'],
    ([url]) => apiClient.get<{ data: TaskResource[]; max_level: number }>(url).then(({ response }) => response),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
    },
  );

  const [tasks, setTasks] = useState<null | TaskResource[]>(null);
  const [isManualReimportPending, setIsManualReimportPending] = useState(false);
  const [isAutoReimportPending, setIsAutoReimportPending] = useState(false);

  useEffect(() => () => {
    notifications.forEach((id) => removeNotification(id));
  }, [notifications]);

  const reimportStatus = async (type: 'all' | 'unless-manually-set') => {
    const { response, statusCode } = await apiClient
      .post<{ data: TaskResource[] } | JiraErrorType | DefaultErrorType>(
      `projects/${projectId}/tracking-overview/import-tasks-status-from-jira/${type}`,
    );

    if (statusCode === 200 && 'data' in response) {
      const updatedTasks = response?.data;
      updatedTasks && setTasks(updatedTasks);
    } else {
      const errors = 'message' in response ? [response?.message] : 'errors' in response ? response?.errors : [];
      errors.forEach(e => {
        throw new Error(e);
      });
    }
  };

  const manualReimportStatus = async () => {
    let notificationId: string | null = null;
    try {
      notifications.forEach((id) => removeNotification(id));
      setIsManualReimportPending(true);
      notificationId = showPedningNotification(t);
      setNotifications(prev => (notificationId ? ([...prev, notificationId]) : prev));
      await reimportStatus('all');
      removeNotification(notificationId);
      const successNotification = showSuccessNotification(t);
      setNotifications(prev => (successNotification ? ([...prev, successNotification]) : prev));
    } catch (e) {
      console.error(e);
      notify({ text: { body: e?.message ?? '' } });
    } finally {
      setIsManualReimportPending(false);
      notificationId && removeNotification(notificationId);
    }
  };

  const autoReimportStatus = async () => {
    let notificationId: string | null = null;
    try {
      setIsAutoReimportPending(true);
      notificationId = showPedningNotification(t);
      setNotifications(prev => (notificationId ? ([...prev, notificationId]) : prev));
      await reimportStatus('unless-manually-set');
      setIsJiraResponseSuccessful(true);
      removeNotification(notificationId);
      const successNotification = showSuccessNotification(t);
      setNotifications(prev => (successNotification ? ([...prev, successNotification]) : prev));
    } catch (e) {
      console.error(e);
      notify({ text: { body: e?.message ?? '' } });
    } finally {
      setIsAutoReimportPending(false);
      notificationId && removeNotification(notificationId);
    }
  };

  useEffect(() => {
    if (loadedTasks) {
      setTasks(loadedTasks.data);
      !isDataLoaded && setIsDataLoaded(true);
      if (hasJiraIntegration && isJiraIntegrationSuccessfullyFinished && isInitialization.current) {
        autoReimportStatus();
        isInitialization.current = false;
      }
    }
  }, [loadedTasks]);

  const updateTasks = (task: TaskResource) => {
    setTasks((prevTasks) => prevTasks!.map((taskItem) => (taskItem.id === task.id ? task : taskItem)));
  };

  const downloadOverview = async () => {
    try {
      setIsDownloadPending(true);
      await apiClient.download(`projects/${projectId}/tracking-overview/tasks-export`, 'application/json', 'status-overview');
    } catch (e) {
      console.error(e.message);
      notify({ text: { body: e?.message ?? '', title: t('An error occurred on status overview downloading') } });
    } finally {
      setIsDownloadPending(false);
    }
  };

  const createLink = async (values: Record<string, any>) => {
    try {
      const { statusCode } = await apiClient.post<{
        data: DeliverableLink
      }>(`projects/${projectId}/tasks/${linkAction.task!.id}/links`, {
        body: JSON.stringify(values),
      });

      if (statusCode !== 201) throw new Error();
      const { data: task } = await getTask(linkAction.task!.id, projectId!) as GetTaskTask;
      task && updateTasks(task);
    } catch (e) {
      console.error(e);
      notify({ text: { body: e?.message ?? '', title: t('An error occurred on link creating') } });
    } finally {
      setLinkAction({ link: null, task: null });
    }
  };

  const editLink = async (values: Record<string, any>, id: number) => {
    try {
      const { statusCode } = await apiClient.put<{
        data: DeliverableLink
      }>(`projects/${projectId}/tasks/${linkAction.task!.id}/links/${id}`, {
        body: JSON.stringify(values),
      });

      if (statusCode !== 200) throw new Error();
      const { data: task } = await getTask(linkAction.task!.id, projectId!) as GetTaskTask;
      task && updateTasks(task);
    } catch (e) {
      console.error(e);
      notify({ text: { body: e?.message ?? '', title: t('An error occurred on link editing') } });
    } finally {
      setLinkAction({ link: null, task: null });
    }
  };

  const deleteLink = async (id?: number, taskId?: number) => {
    if (id && taskId) {
      try {
        const { statusCode } = await apiClient.delete(`projects/${projectId}/tasks/${taskId || linkAction.task!.id}/links/${id}`);

        if (statusCode !== 204) throw new Error();
        const { data: task } = await getTask(taskId, projectId!) as GetTaskTask;
        task && updateTasks(task);
      } catch (e) {
        console.error(e);
        notify({ text: { body: e?.message ?? '', title: t('An error occurred on link deleting') } });
      } finally {
        setLinkAction({ link: null, task: null });
      }
    } else {
      notify();
    }
  };

  /* Search */
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchValue = useDebounce<string>(searchQuery, 300);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  /* END Search */

  const filteredTasks = tasks && filterItemsByName(groupItemsByIdAndParentId(tasks), debouncedSearchValue);

  return isLoading && !loadedTasks?.data ? (
    <div
      className={styles.table__loader}
    >
      <Loader />
    </div>
  ) : (
    <div className={styles.tracking}>
      <SubHeader
        title={t('Project tracking overview')}
        fallbackLink={`${prefix}/client/${clientId}/projects`}
      >
        <div className={styles.tracking__subheader}>
          <Button
            onClick={downloadOverview}
            className={styles.downloadButton}
            icon={!isDownloadPending && (
              <img
                src={ExcelSVG}
                alt=''
              />
            )}
            variant={ButtonVariants.SECONDARY}
            iconSize={{ width: 20, height: 20 }}
            disabled={isDownloadPending}
            loading={isDownloadPending}
          >
            {t('Download status overview')}
          </Button>
        </div>
      </SubHeader>
      <div className={styles.content}>
        <header className={styles.header}>
          <div className={styles.header__content}>
            <div className={styles.searchInput__wrap}>
              <svg className={styles.searchInput__icon}>
                <use
                  xlinkHref={`${SearchSVG}#searchSVG`}
                  href={`${SearchSVG}#searchSVG`}
                />
              </svg>
              <input
                value={searchQuery}
                type='text'
                onChange={onSearch}
                className={styles.searchInput}
                placeholder={t('Search')}
              />
            </div>
            {hasJiraIntegration && isJiraResponseSuccessful && !isReadOnlyProjectAccess && !isJiraSyncFailed && (
              <Button
                onClick={manualReimportStatus}
                className={classNames(styles.reimportButton, {
                  [styles.disabled]: isManualReimportPending || isAutoReimportPending || !isJiraIntegrationSuccessfullyFinished,
                })}
                variant={ButtonVariants.SECONDARY}
                disabled={isManualReimportPending || isAutoReimportPending || !isJiraIntegrationSuccessfullyFinished}
                icon={isManualReimportPending ? (
                  <svg className={styles.spinnerIcon}>
                    <use
                      xlinkHref={`${SpinnerSVG}#spinnerSVG`}
                      href={`${SpinnerSVG}#spinnerSVG`}
                    />
                  </svg>
                ) : (
                  <img
                    src={JiraSVG}
                    alt='jira'
                  />
                )}
              >
                {t('Re-import status from Jira')}
              </Button>
            )}
          </div>
          <div className={classNames(styles.table__header, styles.table__row)}>
            <p>{t('Tasks')}</p>
            <p className={styles.hideOnMobile}>{t('Duration')}</p>
            <p className={classNames(styles.hideOnMobile, styles.hideOnTabletPortrait)}>{t('Status')}</p>
            <p className={classNames(styles.hideOnMobile, styles.hideOnTabletPortrait)}>
              {t('Last status update')}
            </p>
            <p className={styles.attachmentTitle}>{t('Attachments')}</p>
          </div>
        </header>
        <section className={classNames(styles.table, { [styles.table_validating]: isValidating })}>
          {filteredTasks?.length ? filteredTasks.map((task) => (
            <ScheduleAccordion
              key={task.id}
              task={task}
              updateTasks={updateTasks}
              deleteLink={deleteLink}
              hasJiraIntegration={hasJiraIntegration && isJiraResponseSuccessful}
              isReadOnlyProjectAccess={
                isReadOnlyProjectAccess
                || isManualReimportPending
                || isAutoReimportPending
                || (hasJiraIntegration && !isJiraIntegrationSuccessfullyFinished && !isJiraSyncFailed)
              }
              jiraIntegrationFailed={isJiraSyncFailed}
            />
          )) : (
            <div className={styles.emptyState}>
              <svg>
                <use
                  xlinkHref={`${NoTaskFoundSVG}#noTaskFoundSVG`}
                  href={`${NoTaskFoundSVG}#noTaskFoundSVG`}
                />
              </svg>
              <p>{t('No task found with this name')}</p>
            </div>
          )}
        </section>
        <Drawer
          isOpen={!!linkAction.task?.id}
          setIsOpen={() => setLinkAction({ link: null, task: null })}
          title={linkAction.link ? linkAction.task?.title || '' : t('Attach new link')}
          subTitle={linkAction.link ? t('Edit attached link') : ''}
        >
          <DeliverableLinkForm
            createLink={createLink}
            editLink={editLink}
            deleteLink={() => deleteLink(linkAction?.link?.id, linkAction?.task?.id)}
            activeLink={linkAction.link}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default ProjectTracking;
