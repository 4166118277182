import {
  DataGrid,
  GridColDef,
  GridSortItem,
  GridColumnMenu,
  GridColumnMenuProps,
  GridLogicOperator,
  GridFilterModel,
  ColumnHeaderFilterIconButtonProps,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
  gridPreferencePanelStateSelector,
} from '@mui/x-data-grid';
import { Badge, Pagination, PaginationItem } from '@mui/material';
import classNames from 'classnames';
import { forwardRef, useCallback, useEffect } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { unstable_useId as useId } from '@mui/utils';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../../Loader/Loader';

import CheckboxItem from '../CheckboxItem/CheckboxItem';
import { ReactComponent as DoubleArrowLeftSVG } from '../../../public/media/double-angle-left.svg';
import { filterPositionAnchorEl, filters } from '../../../store/table';
import { Status } from '../StatusLabel/types';

import SortSVG from '../../../public/media/sort.svg';
import FilteredSVG from '../../../public/media/filtered.svg';
import FilterdSVG from '../../../public/media/Filter.svg';
import SortArrowSVG from '../../../public/media/arrow-down.svg';

import styles from './Table.module.scss';

type TableProps = {
  rows?: { [key: string]: string | number | Status | null | string[] | boolean }[];
  columns: GridColDef[];
  onPageChange: (page: number) => void;
  isLoading: boolean;
  totalPages: number;
  page: number;
  onSortModelChange?: (model: GridSortItem | null) => void;
  slots?: any;
  onFilterModelChange?: (args: Record<string, any>) => void;
  initSortModel?: GridSortItem;
};

const CustomSwitch = ({ checked, onClick, name }: { checked: boolean; onClick: (e: any) => void; name: string }) => (
  <CheckboxItem
    value={checked}
    onChange={onClick}
    label=''
    name={name}
  />
);

const UnsortedIcon = () => (
  <svg className={styles.sortArrowSVG}>
    <use
      xlinkHref={`${SortSVG}#sortSVG`}
      href={`${SortSVG}#sortSVG`}
    />
  </svg>
);

const FilterIcon = () => (
  <svg className={styles.menuIcon}>
    <use
      xlinkHref={`${FilterdSVG}#filterSVG`}
      href={`${FilterdSVG}#filterSVG`}
    />
  </svg>
);

const SortArrowIcon = () => (
  <svg className={classNames(styles.menuIcon)}>
    <use
      xlinkHref={`${SortArrowSVG}#arrowDownSVG`}
      href={`${SortArrowSVG}#arrowDownSVG`}
    />
  </svg>
);

const FilteredIcon = () => (
  <svg className={styles.filteredSVG}>
    <use
      xlinkHref={`${FilteredSVG}#filteredSVG`}
      href={`${FilteredSVG}#filteredSVG`}
    />
  </svg>
);

const ColumnMenu = (props: GridColumnMenuProps) => {
  const setFilterButtonEl = useSetAtom(filterPositionAnchorEl);
  const element = props?.open && document.querySelector(`[data-field="${props?.colDef.field}"]`);

  // When the filter panel is opened and the user clicks on the column menu button,
  // the filter panel momentarily appears in an incorrect position.
  // This setTimeout serves as a workaround for the issue related to the anchorEl position.
  // TODO: find a better solution
  setTimeout(() => {
    element && setFilterButtonEl(element);
  }, 100);

  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  );
};

const CustomFilterIcon = (props: ColumnHeaderFilterIconButtonProps) => {
  const { counter = 0, field, onClick } = props;
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const preferencePanel = useGridSelector(apiRef, gridPreferencePanelStateSelector);
  const labelId = useId();
  const panelId = useId();
  const currentFilters = useAtomValue(filters);

  const isFilterPanelOpen = preferencePanel.open && preferencePanel.labelId === labelId;

  const toggleFilter = useCallback((event: React.MouseEvent<HTMLButtonElement>, isOpen: boolean) => {
    event.preventDefault();
    event.stopPropagation();

    if (isOpen) {
      apiRef.current.hideFilterPanel();
    } else {
      apiRef.current.showFilterPanel(field, panelId, labelId);
    }
    if (onClick) {
      onClick(apiRef.current.getColumnHeaderParams(field), event);
    }
  }, [apiRef, field, onClick, panelId, labelId, currentFilters, isFilterPanelOpen]);

  const iconButton = (
    <rootProps.slots.baseIconButton
      id={labelId}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => toggleFilter(e, isFilterPanelOpen)}
      color='default'
      aria-label={apiRef.current.getLocaleText('columnHeaderFiltersLabel')}
      size='small'
      tabIndex={-1}
      aria-haspopup='menu'
      aria-expanded={isFilterPanelOpen}
      aria-controls={isFilterPanelOpen ? panelId : undefined}
      {...rootProps.slotProps?.baseIconButton}
    >
      <rootProps.slots.columnFilteredIcon fontSize='small' />
    </rootProps.slots.baseIconButton>
  );

  const shouldShowIcon = (savedFilters: GridFilterModel | null) => {
    const columnFilters = savedFilters?.items.find((scope) => scope.field === field)?.value || null;

    if (columnFilters && Array.isArray(columnFilters)) {
      const someFiltersPass = columnFilters.some(item => item !== null);
      const noFilters = columnFilters.length === 0;

      return someFiltersPass || noFilters;
    }
    if (columnFilters) return true;

    return false;
  };

  if (shouldShowIcon(currentFilters)) {
    return (
      <rootProps.slots.baseTooltip
        enterDelay={1000}
        {...rootProps.slotProps?.baseTooltip}
      >
        <div>
          {counter > 1 && (
          <Badge
            badgeContent={counter}
            color='default'
          >
            {iconButton}
          </Badge>
          )}
          {counter <= 1 && iconButton}
        </div>
      </rootProps.slots.baseTooltip>
    );
  } else {
    return null;
  }
};

const Table = forwardRef<any, TableProps>(
  ({
    rows, columns, onPageChange, isLoading, totalPages, page, onSortModelChange, slots, onFilterModelChange, initSortModel,
  }, ref) => {
    const { pathname } = useLocation();
    const [filterButtonEl, setFilterButtonEl] = useAtom(filterPositionAnchorEl);
    const [currentFilters, setCurrentFilters] = useAtom(filters);
    const { t } = useTranslation();

    useEffect(() => {
      setCurrentFilters(null);
    }, [pathname]);

    const onFilterModelChangeHandler = (model: GridFilterModel) => {
      function filterData(items: GridFilterModel['items']): GridFilterModel['items'] {
        const lastItemsMap: { [key: string]: GridFilterModel['items'][number] } = {};

        items.forEach((item) => {
          const existingItem = lastItemsMap[item.field];
          if (!existingItem) {
            // If there's no existing item for this field, add the current item
            lastItemsMap[item.field] = item;
          } else if (item.value === null) {
            // If the current item has a null value (assigned on reset filter button click), delete the existing item
            delete lastItemsMap[item.field];
          } else if (existingItem.id === item.id) {
            // If the existing item has the same id, update regardless of value
            lastItemsMap[item.field] = item;
          } else if (item.value !== undefined) {
            // If the existing item has a different id and the current item has a value, update
            lastItemsMap[item.field] = item;
          }
        });

        return Object.values(lastItemsMap);
      }

      const newvalue = {
        ...currentFilters,
        ...model,
        items: filterData([...(currentFilters?.items ?? []), ...model.items]),
      };
      setCurrentFilters(newvalue);
      const currentFilter = newvalue.items.reduce((acc, curr) => ({ ...acc, [curr.field]: curr.value }), {});
      onFilterModelChange?.(currentFilter);
    };

    return (
      <section
        className={classNames(styles.table, {
          [styles.table_loading]: isLoading,
        })}
      >
        {rows && columns ? (
          <>
            <DataGrid
              ref={ref}
              rows={rows}
              columns={columns}
              getRowHeight={() => 'auto'}
              rowCount={15}
              columnHeaderHeight={29}
              paginationMode='server'
              sortingMode='server'
              filterMode='server'
              localeText={{
                columnMenuSortAsc: () => t('Sort ascending'),
                columnMenuSortDesc: () => t('Sort descending'),
                columnMenuLabel: t('Filter'),
              }}
              onSortModelChange={(model) => {
                const currentSort = Array.isArray(model) ? model[0] : null;
                onSortModelChange?.(currentSort);
              }}
              slots={{
                baseSwitch: CustomSwitch,
                columnUnsortedIcon: UnsortedIcon,
                columnFilteredIcon: FilteredIcon,
                columnMenu: ColumnMenu,
                columnHeaderFilterIconButton: CustomFilterIcon,
                columnMenuFilterIcon: FilterIcon,
                columnMenuSortAscendingIcon: SortArrowIcon,
                columnMenuSortDescendingIcon: SortArrowIcon,
                ...slots,
              }}
              slotProps={{
                columnsPanel: {
                  disableHideAllButton: true,
                  disableShowAllButton: true,
                  getTogglableColumns: (clmns: GridColDef[]) => clmns.filter((column) => column.hideable).map((column) => column.field),
                },
                panel: {
                  placement: 'bottom-end',
                  ...(filterButtonEl ? { anchorEl: filterButtonEl } : {}),
                },
                columnHeaderFilterIconButton: {
                  onClick: ({ colDef }) => {
                    const element = document.querySelector(`[data-field="${colDef.field}"]`);
                    setFilterButtonEl(element);
                  },
                },

                filterPanel: {
                  logicOperators: [GridLogicOperator.And],
                  filterFormProps: {
                    columnInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      display: 'none',
                      sx: { mt: 'auto', display: 'none' },
                    },
                    operatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: { mt: 'auto', display: 'none' },
                    },
                    valueInputProps: {
                      InputComponentProps: {
                        variant: 'outlined',
                        size: 'small',
                      },
                    },
                    deleteIconProps: {
                      sx: {
                        display: 'none',
                        '& .MuiSvgIcon-root': { color: '#d32f2f' },
                      },
                    },
                  },
                },
              }}
              onFilterModelChange={(model) => {
                onFilterModelChangeHandler(model);
                return model;
              }}
              initialState={{
                sorting: {
                  sortModel: initSortModel ? [initSortModel] : [],
                },
              }}
              disableVirtualization
              disableRowSelectionOnClick
              hideFooterSelectedRowCount
              autoPageSize
              hideFooter
              autoHeight
            />
            {totalPages > 1 && (
              <Pagination
                color='primary'
                showFirstButton
                showLastButton
                page={page}
                siblingCount={0}
                boundaryCount={1}
                count={totalPages}
                className={styles.pagination}
                onChange={(e, pageNumber) => {
                  onPageChange?.(pageNumber);
                }}
                renderItem={(item) => (
                  <PaginationItem
                    className={styles.pagination__item}
                    components={{ first: DoubleArrowLeftSVG, last: DoubleArrowLeftSVG }}
                    {...item}
                  />
                )}
              />
            )}
          </>
        ) : (
          <Loader className={styles.table__loader} />
        )}
      </section>
    );
  },
);

export default Table;
