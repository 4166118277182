import dayjs from 'dayjs';
import { FormikValues, useFormik } from 'formik';
import { useAtomValue } from 'jotai/index';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useParams } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from '../../../../../../constants.ts';
import { ReactComponent as RiskSVG } from '../../../../../../public/media/risk-custom.svg';
import { ReactComponent as CheckedSVG } from '../../../../../../public/media/checked.svg';
import { ReactComponent as PlusSVG } from '../../../../../../public/media/plus.svg';
import UserSVG from '../../../../../../public/media/user.svg';
import CalendarSVG from '../../../../../../public/media/calendar_check.svg';
import { userAtom } from '../../../../../../store/auth.ts';

import { NotificationStatus, notify } from '../../../../../../store/notifications';
import Button from '../../../../../UIKit/Button/Button';
import DatePicker from '../../../../../UIKit/DatePicker/DatePicker.tsx';
import Input from '../../../../../UIKit/Input/Input';
import styles from './AddRiskForm.module.scss';
import { IProjectRisk } from '../types';
import apiClient from '../../../../../../apiClient';

interface IAddRiskFormProps {
  closeForm: () => void;
  onRiskAdd: (newRisk: IProjectRisk) => void;
}

interface IFieldWrapperProps {
  t: (key: string) => string;
  children: ReactNode;
  label: string;
  name: string;
  icon: ReactNode;
  actionButton?: ReactNode;
}

const FieldWrapper = ({
  t, children, label, name, icon, actionButton,
}: IFieldWrapperProps) => (
  <div className={styles.fieldWrapper}>
    <header className={styles.fieldWrapper__header}>
      <label
        className={styles.fieldWrapper__header__label}
        htmlFor={name}
      >
        {icon}
        {t(label)}
      </label>
      {actionButton && actionButton}

    </header>
    <div className={styles.fieldWrapper__field}>
      {children}
    </div>
  </div>

);

interface FormValues {
  caption: string;
  explanation: string;
  mitigation: string;
  owner: string;
  due_date: string | null;
}

const AddRiskForm = ({ closeForm, onRiskAdd }: IAddRiskFormProps) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const userData = useAtomValue(userAtom);

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
    isValid,
    setFieldValue,
    setTouched,
  }: FormikValues = useFormik<FormValues>({
    validateOnMount: true,
    initialValues: {
      caption: '',
      explanation: '',
      mitigation: '',
      owner: '',
      due_date: null,
    },
    validationSchema: Yup.object<FormValues>({
      caption: Yup.string()
        .trim()
        .max(100, t('Must be 100 characters at most'))
        .required(t('Risk item is required')),
      explanation: Yup.string()
        .trim()
        .max(500, t('Must be 500 characters at most')),
      mitigation: Yup.string()
        .trim()
        .max(500, t('Must be 500 characters at most')),
      owner: Yup.string().required(t('Owner is required')),
      due_date: Yup.date().nullable().transform((value) => {
        if (dayjs(value).isValid()) {
          return value;
        }
      })
        .min(dayjs().startOf('day').toDate(), t('Due date can not be set earlier than today'))
        .required(t('Due date is required')),
    }),
    onSubmit: async (submitValues: FormValues) => {
      try {
        const { statusCode, response } = await apiClient.post<{ data: IProjectRisk, message?: string }>(`projects/${projectId}/risks`, {
          body: JSON.stringify({ ...submitValues, due_date: dayjs(submitValues.due_date).format(DEFAULT_DATE_FORMAT) }),
        });

        if (statusCode === 201) {
          onRiskAdd(response.data);
          notify({ status: NotificationStatus.SUCCESS, text: { title: t('Success!'), body: t('Risk added') } });
          closeForm();
        } else {
          throw new Error(response?.message);
        }
      } catch (e) {
        notify(e?.message ? { text: { body: e.message } } : {});
        console.error(e.message);
      }
    },
  });

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.form__content}>
        <FieldWrapper
          name='caption'
          t={t}
          icon={<RiskSVG />}
          label={t('Risk Item')}
          actionButton={(
            <button
              type='button'
              disabled={!values.caption}
              className={styles.actionButton}
              onClick={() => setFieldValue('caption', '')}
            >
              {t('Clear field')}
            </button>
          )}
        >
          <Input
            type='textarea'
            fullWidth
            value={values.caption}
            setValue={handleChange}
            onBlur={handleBlur}
            id='caption'
            label={t('Name')}
            name='caption'
            error={!!(touched.caption && errors.caption)}
            errorMessage={errors.caption}
            className={styles.form__input}
            multiline
            maxRows={3}
          />
        </FieldWrapper>
        <FieldWrapper
          actionButton={(
            <button
              type='button'
              disabled={!values.explanation}
              className={styles.actionButton}
              onClick={() => setFieldValue('explanation', '')}
            >
              {t('Clear field')}
            </button>
          )}
          name='explanation'
          t={t}
          label={t('Explanation')}
          icon={<RiskSVG />}
        >
          <Input
            type='textarea'
            fullWidth
            value={values.explanation}
            setValue={handleChange}
            onBlur={handleBlur}
            id='explanation'
            label={t('Enter description')}
            name='explanation'
            error={!!(touched.explanation && errors.explanation)}
            errorMessage={errors.explanation}
            className={styles.form__input}
            multiline
            maxRows={3}
          />
        </FieldWrapper>
        <FieldWrapper
          actionButton={(
            <button
              type='button'
              disabled={!values.mitigation}
              className={styles.actionButton}
              onClick={() => setFieldValue('mitigation', '')}
            >
              {t('Clear field')}
            </button>
          )}
          name='mitigation'
          t={t}
          label={t('Mitigation actions')}
          icon={<CheckedSVG />}
        >
          <Input
            type='textarea'
            fullWidth
            value={values.mitigation}
            setValue={handleChange}
            onBlur={handleBlur}
            id='mitigation'
            label={t('Enter description')}
            name='mitigation'
            error={!!(touched.mitigation && errors.mitigation)}
            errorMessage={errors.mitigation}
            className={styles.form__input}
            multiline
            maxRows={3}
          />
        </FieldWrapper>
        <FieldWrapper
          actionButton={(
            <button
              type='button'
              disabled={!values.owner}
              className={styles.actionButton}
              onClick={() => setFieldValue('owner', '')}
            >
              {t('Clear field')}
            </button>
          )}
          name='owner'
          t={t}
          label={t('Owner')}
          icon={(
            <svg>
              <use
                xlinkHref={`${UserSVG}#userSVG`}
                href={`${UserSVG}#userSVG`}
              />
            </svg>
          )}
        >
          <Input
            type='input'
            fullWidth
            value={values.owner}
            setValue={handleChange}
            onBlur={handleBlur}
            id='owner'
            label={t('Enter owner\'s name')}
            name='owner'
            error={!!(touched.owner && errors.owner)}
            errorMessage={errors.owner}
            className={styles.form__input}
          />
        </FieldWrapper>
        <FieldWrapper
          actionButton={(
            <button
              type='button'
              disabled={!values.due_date}
              className={styles.actionButton}
              onClick={() => setFieldValue('due_date', null)}
            >
              {t('Clear field')}
            </button>
          )}
          name='due_date'
          t={t}
          label={t('Due date')}
          icon={(
            <svg>
              <use
                xlinkHref={`${CalendarSVG}#calendarSVG`}
                href={`${CalendarSVG}#calendarSVG`}
              />
            </svg>
          )}
        >
          <DatePicker
            label={t('Select due date')}
            format={userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT}
            value={values.due_date}
            setValue={date => {
              setTouched({ due_date: true });
              setFieldValue('due_date', date);
            }}
            error={!!(touched.due_date && errors.due_date)}
            errorMessage={errors.due_date}
            disablePast
          />
        </FieldWrapper>
      </div>

      <div className={styles.form__footer}>
        <Button
          iconSize={{ width: 18, height: 18 }}
          icon={<PlusSVG />}
          className={styles.button}
          type='submit'
          loading={isSubmitting}
          disabled={isSubmitting || !isValid}
        >
          {t('Add Item')}
        </Button>
      </div>
    </form>
  );
};

export default AddRiskForm;
