import { TextField } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import styles from './Input.module.scss';

export type InputProps = {
  value: string | number,
  setValue: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
  onKeyDown?: (e: React.KeyboardEvent) => void,
  onFocus?: (e: React.FocusEvent) => void,
  type?: string,
  id: string,
  label?: string,
  required?: boolean,
  fullWidth?: boolean,
  error?: boolean,
  errorMessage?: string;
  disabled?: boolean,
  className?: string,
  size?: 'small' | 'medium',
  name?: string,
  inputProps?: Record<string, any>,
  placeholder?: string,
  multiline?: boolean,
  maxRows?: number,
};

const Input = ({
  value,
  setValue,
  onBlur,
  onKeyDown,
  type = 'text',
  id,
  label,
  required = false,
  fullWidth = false,
  error = false,
  disabled = false,
  className,
  size,
  name,
  errorMessage,
  onFocus,
  inputProps,
  placeholder,
  multiline,
  maxRows,
}: InputProps) => (
  <div>
    <TextField
      value={value}
      type={type}
      onChange={setValue}
      onBlur={onBlur}
      error={error}
      disabled={disabled}
      className={classNames(styles.input, className, {
        [styles.disabled]: disabled,
        'field-error': error,
      })}
      id={id}
      label={label}
      variant='outlined'
      required={required}
      fullWidth={fullWidth}
      size={size}
      name={name}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      inputProps={inputProps}
      placeholder={placeholder}
      {...(type === 'textarea' ? { maxRows, multiline } : {})}
    />
    {(errorMessage && error) && <p className={styles.error}>{errorMessage}</p>}
  </div>
);

export default Input;
