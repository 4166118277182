import React from 'react';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CloseSVG from '../../../../public/media/close.svg';
import DeleteSVG from '../../../../public/media/delete-icon.svg';
import EditSVG from '../../../../public/media/edit-icon.svg';
import PlusSVG from '../../../../public/media/plus.svg';
import Button, { ButtonVariants } from '../../../UIKit/Button/Button.tsx';
import { editLinkAtom } from '../../projectTracking.atom.ts';
import { TaskResource } from '../../../pages/NewProject/NewProjectSummary/types.ts';
import styles from './Links.module.scss';

type Link = {
  id: number;
  caption: string;
  link: string;
};

const Links = ({
  task,
  deletedLink,
  onLinkDeleting,
  confirmDeleting,
  closePopover,
  isReadOnlyProjectAccess,
}: {
  task: TaskResource;
  deletedLink: Link | null;
  onLinkDeleting: (link: Link) => void;
  confirmDeleting: (link: Link) => void;
  closePopover: () => void;
  isReadOnlyProjectAccess?: boolean;
}) => {
  const { t } = useTranslation();
  const setLinkAction = useSetAtom(editLinkAtom);

  const handleEditLink = (link: Link): void => {
    setLinkAction({
      link,
      task: { id: task.id, title: task.name },
    });
    closePopover();
  };

  return (
    <div className={styles.attachedLinksDropdown__padding}>
      <header className={styles.attachedLinksDropdown__header}>
        <p className={styles.attachedLinksDropdown__quantity}>
          {t('Links')}
          {' '}
          (
          {task.links.length}
          )
        </p>
        {!isReadOnlyProjectAccess && (
        <Button
          className={styles.attachedLinksDropdown__addButton}
          onClick={() => {
            setLinkAction({
              link: null,
              task: { id: task.id, title: task.name },
            });
            closePopover();
          }}
          icon={(
            <svg>
              <use
                xlinkHref={`${PlusSVG}#plusSVG`}
                href={`${PlusSVG}#plusSVG`}
              />
            </svg>
          )}
          iconSize={{ width: 16, height: 16 }}
          variant={ButtonVariants.SECONDARY}
        >
          {t('Attach link')}
        </Button>
        )}
      </header>
      <div className={styles.attachedLinksDropdown__content}>
        {task.links.map((link: Link) => (
          <div
            className={styles.listedLink}
            key={link.id}
          >
            {!isReadOnlyProjectAccess && (
            <Button
              aria-label={
                deletedLink?.id === link.id
                  ? t('Confirm link deleting')
                  : t('Delete attached link')
              }
              variant={ButtonVariants.SECONDARY}
              className={styles.listedLink__button}
              onClick={() => onLinkDeleting(link)}
            >
              {deletedLink?.id === link.id ? (
                <svg className={styles.closeSVG}>
                  <use
                    xlinkHref={`${CloseSVG}#closeSVG`}
                    href={`${CloseSVG}#closeSVG`}
                  />
                </svg>
              ) : (
                <svg className={styles.deleteSVG}>
                  <use
                    xlinkHref={`${DeleteSVG}#deleteSVG`}
                    href={`${DeleteSVG}#deleteSVG`}
                  />
                </svg>
              )}
            </Button>
            )}
            {deletedLink?.id === link.id ? (
              <button
                type='button'
                className={styles.linkDeleteButton}
                onClick={() => confirmDeleting(link)}
              >
                {t('Yes, delete')}
              </button>
            ) : (
              <div className={styles.attachedLink}>
                <a
                  target='_blank'
                  className={classNames(styles.attachedLink__link, {
                    [styles.attachedLink__link_readonlyMode]: isReadOnlyProjectAccess,
                  })}
                  href={link.link}
                  rel='noreferrer'
                  title={link.caption}
                >
                  {link.caption}
                </a>
                {!isReadOnlyProjectAccess && (
                <Button
                  aria-label={t('Edit attached link')}
                  type='button'
                  onClick={() => handleEditLink(link)}
                  variant={ButtonVariants.SECONDARY}
                  className={styles.listedLink__button}
                >
                  <svg>
                    <use
                      xlinkHref={`${EditSVG}#editSVG`}
                      href={`${EditSVG}#editSVG`}
                    />
                  </svg>
                </Button>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Links;
