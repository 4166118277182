import { ProjectJiraIntegrationStatus } from '../NewProject/NewProjectSummary/types';
import { CustomerResource } from '../Customers/types';
import { TemplateResource } from '../Templates/types';
import { Status } from '../../UIKit/StatusLabel/types.ts';
import { ClientMemberResource } from '../Users/UsersList/types.ts';

export enum ProjectProirityValue {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}
export type ProjectJiraIntegrationResource = {
  id: number;
  key: string;
  url: string;
  status: {
    value: ProjectJiraIntegrationStatus;
    caption: string;
    state: string;
  };
};

export type ProjectProirity = {
  value: ProjectProirityValue,
  caption: string
};

export type ProjectResource = {
  id: number;
  caption: string;
  begin: string;
  end: string;
  budget: number;
  customer: CustomerResource;
  template: TemplateResource;
  status: Status;
  description?: string;
  owners: ClientMemberResource[];
  answers: Record<string, number[]>;
  permissions: string[];
  step: number;
  salesforce: string;
  jiraIntegration: ProjectJiraIntegrationResource | null;
  priority?: ProjectProirity
};

export type VersionResource = {
  id: number;
  creator: string;
  number: number;
  created_at: string;
  parent: VersionResource;
};
