import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import classNames from 'classnames';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import de from 'dayjs/locale/de';
import en from 'dayjs/locale/en';
import { useTranslation } from 'react-i18next';
import styles from './DatePicker.module.scss';

type DatePickerProps = {
  label: string,
  disabled?: boolean,
  name?: string,
  value: Dayjs | null,
  className?: string,
  format?: string,
  error?: boolean,
  errorMessage?: string,
  setValue: (date: Dayjs | null) => void,
  disablePast?: boolean,
};

dayjs.locale(de);
dayjs.locale(en);

// TODO: Add correct locale
const DatePicker = ({
  label,
  disabled = false,
  name,
  value,
  className,
  format = 'DD\\MM\\YYYY',
  error,
  errorMessage,
  setValue,
  disablePast,
}: DatePickerProps) => {
  const { i18n } = useTranslation();

  return (
    <div>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        dateFormats={{
          fullDate: format,
        }}
        adapterLocale={i18n.language}
      >
        <MuiDatePicker
          label={label}
          disabled={disabled}
          name={name}
          format={format}
          value={value}
          onChange={setValue}
          minDate={dayjs('01.01.2000')}
          maxDate={dayjs('01.01.2500')}
          className={classNames(styles.datePicker, className, {
            [styles.disabled]: disabled,
            [styles.error]: error,
            'field-error': error,
          })}
          disablePast={disablePast}
        />
      </LocalizationProvider>
      {(errorMessage && error) && <p className={styles.error}>{errorMessage}</p>}
    </div>
  );
};

export default DatePicker;
